import {useContext} from "react";
import {RouterContext} from "../../contexts";

const useRouter = () => {
    const context = useContext(RouterContext);
    if (!context) {
        throw new Error('useRouter должен быть внутри RouterProvider');
    }
    return context;
};

export default useRouter;
