import React from "react";
import CatalogProducts from "../Catalog/CatalogProducts";
import {useSelector} from "react-redux";
import {RootState} from "../../store/rootReducer";

const SearchPageContainer: React.FC = () => {
    const { searchResult, isLoaded, isLoading, query } = useSelector((state: RootState) => state.search);

    return <CatalogProducts
        title={"Результат поиска по запросу: " + query}
        products={searchResult ?? []}
        isLoading={isLoading}
        isLoaded={isLoaded}
    />
}

export default SearchPageContainer;