import React from 'react';
import styles from './IconButton.module.sass';
import Box from '@components/general-components/boxes/Box';

interface IconButtonProps {
    icon: JSX.Element;
    onClick: () => void;
    style?: React.CSSProperties;
    children?: React.ReactNode;
    color?: string;
}

const IconButton: React.FC<IconButtonProps> = ({ icon, onClick, color = 'black', style, children }) => {

    const customStyle = {
        padding: '5px',
        color: color,
        ...style
    }

    const iconWithUpdatedStyle = React.cloneElement(icon, {
        style: {
            ...icon.props.style,
            color: color
        },
        color: color
    });

    return (
        <button className={styles.icon_button} onClick={onClick} style={customStyle}>
            <Box width='100%' height='100%'>{iconWithUpdatedStyle} {children}</Box>
        </button>
    )
}

export default IconButton;