import {CategoriesProductsApi, Configuration, UserApi} from "@domains/ServiceApi/GeneratedData";

debugger;

const BASE_URL = process.env.REACT_APP_API_URL + '/api/v1';

const configuration = new Configuration({
    basePath: BASE_URL
});

if (!configuration) {
    throw new Error('Configuration instance is not created.');
}

export const productsApi: CategoriesProductsApi = new CategoriesProductsApi(configuration, BASE_URL);
export const userApi: UserApi = new UserApi(configuration, BASE_URL);