import {call, put, takeEvery} from "redux-saga/effects";
import {
    GET_CATEGORIES,
    SET_CATEGORIES,
    setStatusLoadingCategory,
} from "./actions";
import {
    CategoriesProductsApi,
    V1GetCategoriesResp,
} from "@domains/ServiceApi/GeneratedData";
import {AxiosResponse} from "axios";
import HttpStatus from "@domains/Enums/HttpStatus";
import {convertCategoryArrayToInnerArray} from "@domains/Category/Category";
import {productsApi} from "../api/recsysApi";

function* getCategories(action: any) {
    try {
        yield put(setStatusLoadingCategory(true));
        const response: AxiosResponse<V1GetCategoriesResp> = yield call(productsApi.categoriesGet.bind(productsApi));

        if (response.status === HttpStatus.OK) {
            const categoryData = convertCategoryArrayToInnerArray(response.data.data);
            yield put({type: SET_CATEGORIES, payload: categoryData});
        }

        yield put(setStatusLoadingCategory(false));
    } catch (e) {
        yield put(setStatusLoadingCategory(true));
        console.error(e);
    }
}

export function* watchGetCategories() {
    yield takeEvery(GET_CATEGORIES, getCategories);
}