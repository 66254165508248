import React, {useState} from "react";
import {Box, Button, Divider, IconButton, Typography} from "@general-components/common-components";
import {KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import styles from "./ToggleText.module.sass";

interface TpggleTextProps {
    title: string;
    text: string;
}

const ToggleText: React.FC<TpggleTextProps> = ({ title, text }) => {
    const [isVisible, setIsVisible] = useState<boolean>(false);

    const handleVisibility= () => {
        setIsVisible(!isVisible);
    }

    return (
        <Box fullwidth>
            <Divider />
            <Box display={'flex'} flexDirection={'row'} alignContent={'space-between'} fullwidth={true}>
                <Typography color="primary" variant="h5" align="left" margin={"0px 0px 0px 0px"}>
                    Подробнее о товаре
                </Typography>
                {isVisible
                    ? <IconButton icon={<KeyboardArrowUp />} onClick={handleVisibility} />
                    : <IconButton icon={<KeyboardArrowDown />} onClick={handleVisibility} />
                }
            </Box>
            {isVisible && (
                <Box
                    margin={"10px 0px 10px 0px"}
                    display={'flex'}
                    fullwidth
                    alignItems={'flex-start'}
                    alignContent={'flex-start'}
                >
                    <Typography variant={"body1"} color={"textPrimary"}>
                        {text}
                    </Typography>
                </Box>
            )}
            <Divider />

        </Box>
    )
}

export default ToggleText;