import React, {useEffect, useState} from 'react';
import {Box, Card, Grid, Typography} from "@general-components/common-components";
import Background from "@images/desktop-phone/background.png";
import MobileFrame from 'pages/MobileFrame/MobileFrame';

interface DesktopWithPhoneProps {
    children: React.ReactNode;
}

const DesktopWithPhone: React.FC<DesktopWithPhoneProps> = ({children}) => {



    return (
        <Box
            backgroundImage={Background}
            fullwidth height={"100%"}
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <MobileFrame>
                    <Box
                        width="375px"
                        height="667px"
                    >
                        {children}
                    </Box>
                </MobileFrame>
            </Box>
        </Box>
    );

}

export default DesktopWithPhone;