import {Category} from "@domains/generatedAPI";

export interface CategoryData {
    id: number;
    name: string;
    imageSrc: string;
}

export type ConvertCategoryDtoToInner = (category: Category | undefined) => CategoryData | undefined;

export const convertCategoryToInner = (category: Category | undefined) => {
    if (category === undefined) return undefined;

    return {
        id: category.id,
        name: category.name,
        imageSrc: category.image
    }
}

export const convertCategoryArrayToInnerArray = (categories: Array<Category> | undefined): CategoryData[] | undefined => {
    if (categories === undefined) return undefined;

    return categories.map(convertCategoryToInner).filter((categoryData): categoryData is CategoryData => categoryData !== undefined);
}
