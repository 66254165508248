import {Notify} from "../../infrastructure/notifications/notification";
import {call, put, takeEvery, select, all} from "redux-saga/effects";
import {
    ADD_OR_PATCH_PRODUCT_TO_BASKET, CLEAR_BASKET,
    GET_BASKET_DATA,
    REMOVE_PRODUCT_FROM_BASKET,
    SET_BASKET_DATA, setBasketData,
    setStatusLoading
} from "./actions";
import {userApi} from "../api/recsysApi";
import {AxiosResponse} from "axios";
import {V1AddProductToCartRequest, V1GetCartResponse} from "@domains/ServiceApi/GeneratedData";
import HttpStatus from "@domains/Enums/HttpStatus";
import {convertProductsDtoToInner, ProductBasketData} from "@domains/Product/ProductBasketData";
import {RootState} from "../rootReducer";
import {PUT_LOGIN_DATA} from "../auth/actions";

function* addBasketProduct (response: AxiosResponse<V1GetCartResponse>) {


    if (response.status === HttpStatus.OK) {
        const basketProducts = convertProductsDtoToInner(response.data.data);
        if (basketProducts !== undefined) {
            yield put({type: PUT_LOGIN_DATA, payload: {}})
            yield put(setBasketData(basketProducts));
        }
    } else {
        Notify.error("Ошибка добавления товара в корзину");
    }
}

function* workerGetBasket(action: any) {
    try {
        const isAuth: boolean = yield select((state: RootState) => state.auth.isAuth);

        if (isAuth) {
            yield put(setStatusLoading(true));
            const response: AxiosResponse<V1GetCartResponse> = yield call(userApi.userCartGet.bind(userApi));
            yield addBasketProduct(response);
            yield put(setStatusLoading(false));
        } else {
            yield put(setBasketData([]));
        }
    } catch (e) {
        Notify.error("Ошибка" + JSON.stringify(e));
        yield put(setStatusLoading(false));
    }
}

function* workerAddProductToBasket(action: any) {
    try {
        const {productId, count} = action.payload;
        yield put(setStatusLoading(true));

        const productsInBasket: Array<ProductBasketData> = yield (select((state: RootState) => state.basket.basketItems));
        const productInBasket = productsInBasket.find(item => item.product.productId === productId);
        if (productInBasket) {
            const response: AxiosResponse<V1GetCartResponse> = yield call(userApi.userCartProductProductIdPatch.bind(userApi), productId, count);
            yield addBasketProduct(response);
        } else {
            const response: AxiosResponse<V1GetCartResponse> = yield call(userApi.userCartProductPost.bind(userApi), {'product_id': productId} as V1AddProductToCartRequest);
            yield addBasketProduct(response);
        }
        yield put(setStatusLoading(false));

    } catch(e) {
        Notify.error("Ошибка" + JSON.stringify(e));
        yield put(setStatusLoading(false));
    }
}

function* workerDeleteProductFromBasket(action: any) {
    try {
        const productId = action.payload;
        yield put(setStatusLoading(true));
        const productsInBasket: Array<ProductBasketData> = yield (select((state: RootState) => state.basket.basketItems));
        const productInBasket = productsInBasket.find(item => item.product.productId === productId);
        if (productInBasket) {
            const response: AxiosResponse<V1GetCartResponse> = yield call(userApi.userCartProductProductIdDelete.bind(userApi), productId);
            yield addBasketProduct(response);
        }

        yield put(setStatusLoading(false));

    } catch(e) {
        Notify.error("Ошибка" + JSON.stringify(e));
        yield put(setStatusLoading(false));
    }
}

function* workerClearBasket(action: any) {
    try {
        yield put(setStatusLoading(true));
        const productsInBasket: Array<ProductBasketData> = yield select((state: RootState) => state.basket.basketItems);
        const calls = productsInBasket.map(item => call(userApi.userCartProductProductIdDelete.bind(userApi), item.product.productId));

        const results: Array<AxiosResponse<V1GetCartResponse>> = yield all(calls);
        if (results.every(result => result.status === HttpStatus.OK)) {
            yield put(setBasketData([]));
            Notify.show("Корзина очищена");
        } else {
            yield call(workerGetBasket, action)
            Notify.error("При очистке корзины возникли ошибки");
        }
        yield put(setStatusLoading(false));

    } catch (e) {
        Notify.error("Ошибка" + JSON.stringify(e));
        yield put(setStatusLoading(false));
    }
}

export function* watchClearBasket() {
    yield takeEvery(CLEAR_BASKET, workerClearBasket);
}

export function* watchDeleteProductFromBasket() {
    yield takeEvery(REMOVE_PRODUCT_FROM_BASKET, workerDeleteProductFromBasket);
}

export function* watchAddProductToBasket() {
    yield takeEvery(ADD_OR_PATCH_PRODUCT_TO_BASKET, workerAddProductToBasket);
}

export function* watchGetBasket() {
    yield takeEvery(GET_BASKET_DATA, workerGetBasket);
}