import React, {useEffect, useState} from "react";
import {Box, Divider, Title, Typography} from "@general-components/common-components";
import {ProductCardData} from "@domains/Product/ProductCardData";
import ProductsHorizontalScroll from "@general-components/horizontal-scroll/ProductsHorizontalScroll";
import {ProductBasketData} from "@domains/Product/ProductBasketData";
import BasketItem from "./BasketItem";
import {useDispatch} from "react-redux";
import {removeAllProductFromBasket} from "../../store/basket_old/actions";
import {IconEmptyBasket} from "@components/icons";
import {clearBasket} from "../../store/basket/actions";

interface BasketPageProps {
    productsBasket: Array<ProductBasketData>
    recommended: Array<ProductCardData>
    isLoadingRecommended: boolean
}

const BasketPage: React.FC<BasketPageProps> = ({ productsBasket, recommended, isLoadingRecommended }) => {

    const [totalSum, setTotalSum] = useState<number>(0);
    const dispatch = useDispatch();

    const handleRemoveAll = () => {
        dispatch(clearBasket());
    }

    useEffect(() => {
        setTotalSum(productsBasket.reduce(function (currentSum, currentNumber) {
            return currentSum + (currentNumber.count * currentNumber.product.price ?? 0)
        }, 0));
    }, [productsBasket])

    const getCorrectWordForm = (count: number): string => {
        const lastDigit = count % 10;
        const lastTwoDigits = count % 100;

        if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
            return `${count} товаров`;
        }

        switch (lastDigit) {
            case 1:
                return `${count} товар`;
            case 2:
            case 3:
            case 4:
                return `${count} товара`;
            default:
                return `${count} товаров`;
        }

    }

    const countGoods = productsBasket.length;

    return (
        <Box style={{WebkitAlignItems: 'flex-start'}} fullwidth={true}>
            <Title title={"Корзина"} />

            {
                countGoods > 0 ? (
                    <Box fullwidth={true}>
                        <Box justifyContent={"space-between"} flexDirection={'row'} fullwidth={true} padding={'20px 0'}>
                            <Typography variant={"body1"} fontWeight={"bolder"} color={"primary"}>{getCorrectWordForm(countGoods)}</Typography>
                            <Box onClick={handleRemoveAll}>
                                <Typography variant={"body1"} fontWeight={"bolder"} color={"secondary"}>Удалить все 🗑️</Typography>
                            </Box>
                        </Box>
                        <Box fullwidth={true}>
                            {
                                productsBasket.map((product) => (
                                    <BasketItem productBasketData={product} />
                                ))
                            }
                        </Box>

                        <Divider />

                        <Box fullwidth={true} justifyContent={'space-between'} flexDirection={'row'}>
                            <Typography variant={"body1"} fontWeight={"bolder"} color={"primary"}>{'Всего'}</Typography>
                            <Typography variant={"body1"} fontWeight={"bolder"} color={"primary"}>{totalSum}</Typography>
                        </Box>

                    </Box>
                ) : (
                    <Box fullwidth={true}>
                        <Box display={'flex'} fullwidth={true} flexDirection={'row'} alignItems={'center'} justifyContent={'center'}>
                            <Box fullwidth={true}>
                                <IconEmptyBasket scale={0.4} />
                            </Box>
                            <Typography variant={"h5"} fontWeight={"bolder"} color={'primary'}>В корзине пока нет товаров</Typography>
                        </Box>
                    </Box>
                )
            }

            <Box fullwidth={true}>
                <ProductsHorizontalScroll title={"Рекомендуем"} products={recommended} isLoading={isLoadingRecommended}/>
            </Box>
        </Box>
    )
}

export default BasketPage;