import {CategoryData} from "@domains/Category/Category";
import {SET_CATEGORIES, SET_STATUS_LOADING_CATEGORY} from "./actions";

interface CategoryState {
    isLoaded: boolean;
    isLoading: boolean;
    categories: Array<CategoryData> | undefined;
}

const initialState: CategoryState = {
    isLoaded: false,
    isLoading: false,
    categories: undefined,
}

const categoryReducer = (state = initialState, action: any): CategoryState => {
    switch (action.type) {
        case SET_CATEGORIES: {
            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                categories: action.payload
            }
        }

        case SET_STATUS_LOADING_CATEGORY: {
            return {
                ...state,
                isLoading: action.payload
            }
        }

        default: {
            return state;
        }
    }

}

export default categoryReducer;