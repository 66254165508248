import {ProductCardData} from "@domains/Product/ProductCardData";
import {RecommendationProductUserRequestDto} from "@domains/Recommended/RecommendationProductUserRequestDto";

export const GET_RECOMMENDED_PRODUCT_USER: string = 'GET_RECOMMENDED_PRODUCT_USER';
export const SET_RECOMMENDED_PRODUCT_USER: string = 'SET_RECOMMENDED_PRODUCT_USER';
export const SET_STATE_RECOMMENDED_PRODUCT_USER: string = 'SET_STATE_RECOMMENDED_PRODUCT_USER';
export const CLEAN_RECOMMENDED_PRODUCT_USER: string = 'CLEAN_RECOMMENDED_PRODUCT_USER';

export const GET_RECOMMENDED_PRODUCT_PRODUCT: string = 'GET_RECOMMENDED_PRODUCT_PRODUCT';
export const SET_RECOMMENDED_PRODUCT_PRODUCT: string = 'SET_RECOMMENDED_PRODUCT_PRODUCT';
export const SET_STATE_RECOMMENDED_PRODUCT_PRODUCT: string = 'SET_STATE_RECOMMENDED_PRODUCT_PRODUCT';

export const GET_RECOMMENDED_PRODUCT_BASKET: string = 'GET_RECOMMENDED_PRODUCT_BASKET';
export const SET_RECOMMENDED_PRODUCT_BASKET: string = 'SET_RECOMMENDED_PRODUCT_BASKET';
export const SET_STATE_RECOMMENDED_PRODUCT_BASKET: string = 'SET_STATE_RECOMMENDED_PRODUCT_BASKET';

export const getRecommendedProductUser = (request: RecommendationProductUserRequestDto) => ({
    type: GET_RECOMMENDED_PRODUCT_USER,
    payload: request,
});

export const setRecommendedProductUser = (products: Array<ProductCardData>) => ({
    type: SET_RECOMMENDED_PRODUCT_USER,
    payload: products,
})

export const setStateRecommendedProductUser = (state: boolean) => {
    return {
        type: SET_STATE_RECOMMENDED_PRODUCT_USER,
        payload: state
    }
}

export const cleanRecommendedProductUser = () => ({
    type: CLEAN_RECOMMENDED_PRODUCT_USER,
    payload: {},
});

export const getRecommendedProductProduct = (productId: number, count: number) => ({
    type: GET_RECOMMENDED_PRODUCT_PRODUCT,
    payload: {productId, count},
});

export const setRecommendedProductProduct = (products: Array<ProductCardData>) => ({
    type: SET_RECOMMENDED_PRODUCT_PRODUCT,
    payload: products,
})

export const setStateRecommendedProductProduct = (state: boolean) => {
    return {
        type: SET_STATE_RECOMMENDED_PRODUCT_PRODUCT,
        payload: state
    }
}

export const getRecommendedProductBasket = (count: number) => ({
    type: GET_RECOMMENDED_PRODUCT_BASKET,
    payload: count,
});

export const setRecommendedProductBasket = (products: Array<ProductCardData>) => ({
    type: SET_RECOMMENDED_PRODUCT_BASKET,
    payload: products,
})

export const setStateRecommendedProductBasket = (state: boolean) => ({
    type: SET_STATE_RECOMMENDED_PRODUCT_BASKET,
    payload: state
})



