import React from 'react'
import LoginForm from './LoginForm';
import {Box} from "@general-components/common-components";

/**
 * Renders the login page component.
 *
 * @return {ReactElement} The rendered login page component.
 */
const LoginPage: React.FC = () => {

    return (
        <Box display='flex' justifyContent='center'>
            <LoginForm />
        </Box>
    )
}

export default LoginPage;