import Login from "@domains/Login/Login";

export const LOGIN = 'LOGIN'
export const PUT_LOGIN_DATA = 'PUT_LOGIN_DATA'
export const LOGOUT = 'LOGOUT'
export const PUT_LOGOUT_DATA = 'PUT_LOGOUT_DATA'
export const SET_STATUS_LOGIN_LOADING = 'SET_STATUS_LOGIN_LOADING';
export const SET_REDIRECT_PATH = 'SET_REDIRECT_PATH';

export const setRedirectPath = (path: string) => {
    return {
        type: SET_REDIRECT_PATH,
        payload: path
    }
}

export const login = (loginData: Login) => {
    return {
        type: LOGIN,
        payload: loginData
    }
}

export const logout = () => {
    return {
        type: LOGOUT,
        payload: {}
    }
}

export const setStatusLoadingLogins = (status: boolean) => {
    return {
        type: SET_STATUS_LOGIN_LOADING,
        payload: status
    }
}