import {Store} from "react-notifications-component";
import 'react-notifications-component/dist/theme.css';

const notification = {
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated animate__fadeIn"],
    animationOut: ["animate__animated animate__fadeOut"]
};

export const Notify = {
    show: (message: string): void => {
        Store.addNotification({
            title: "Уведомление",
            message: message,
            type: "info",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
    },
    error: (message: string): void => {
        Store.addNotification({
            title: "Ошибка!",
            message: message,
            type: "danger",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
    }
}