import React, {useEffect} from 'react';
import Home from "./Home";
import {ProductCardData} from "@domains/Product/ProductCardData";
import {ProductsByDate} from "@domains/Product/ProductsByDate";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/rootReducer";
import {getRecommendedProductUser} from "../../store/recommended/actions";
import {logout} from "../../store/auth/actions";

interface HomeContainerProps {
}

const HomeContainer: React.FC<HomeContainerProps> = ({}) => {
    const dispatch = useDispatch();
    const { recProductsUser, isLoadedRecUser, isLoadingRecUser } = useSelector((state: RootState) => state.recommended);

    const handleLogout = () => {
        dispatch(logout());
    }

    useEffect(() => {
        dispatch(getRecommendedProductUser({
            offset: 0,
            limit: 100
        }));
    }, []);

    return <Home recommended={recProductsUser} purchasedProducts={[]} logout={handleLogout} isLoadingRecommendations={isLoadingRecUser} />

}

export default HomeContainer;