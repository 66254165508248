import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

interface IconBasketProps extends SvgIconProps {

}

const IconBasket: React.FC<IconBasketProps> = ({ color = '#1D2F49', ...props }) => {
    return (
        <SvgIcon {...props} viewBox="0 0 27 24" sx={{ width: '70%', height: '70%' }}>
            <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M21.9842 10L15.6842 4L21.9842 10Z" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M5.1842 10L11.4842 4" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M4.98764 10.0001H22.1812L20.5437 19.0001H6.62512L4.98764 10.0001Z" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    );
}

export default IconBasket;