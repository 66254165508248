import React, { useEffect, useState } from 'react';
import { Box, IconButton } from "@general-components/common-components";
import { AddOutlined, RemoveOutlined } from "@mui/icons-material";
import styled from './NumberField.module.sass';

interface NumberFieldProps {
    value: number;
    onChange: (newValue: number) => void;
}

const NumberField: React.FC<NumberFieldProps> = ({ value, onChange }) => {
    const [currentValue, setCurrentValue] = useState<number>(value);

    useEffect(() => {
        if (currentValue > 999) {
            setCurrentValue(999);
        }
        if (currentValue < 0) {
            setCurrentValue(0);
        }
        onChange(currentValue);
    }, [currentValue, onChange]);

    const increment = () => {
        setCurrentValue(prev => prev + 1);
    };

    const decrement = () => {
        setCurrentValue(prev => prev - 1);
    };

    return (
        <Box className={styled['number-field-container']}>
            <IconButton icon={<RemoveOutlined />} onClick={decrement} />
            <input
                type="number"
                value={currentValue}
                onChange={e => setCurrentValue(Number(e.target.value))}
                disabled
            />
            <IconButton icon={<AddOutlined />} onClick={increment} />
        </Box>
    );
}

export default NumberField;
