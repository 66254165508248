import {DomainProduct} from "@domains/ServiceApi/GeneratedData";

export interface ProductEnergyData {
    proteins: number;
    fats: number;
    calories: number;
    carbohydrates: number;
}

export interface ProductData {
    id: number;
    imageSrc: string;
    name: string;
    price?: number;
    volume?: string;
    description?: string;
    energy?: ProductEnergyData;
}

export type ConvertProductFullDataDtoToInner = (product: DomainProduct | undefined) => ProductData | undefined;

export const convertProductFullDataDtoToInner: ConvertProductFullDataDtoToInner = (product: DomainProduct | undefined) => {
    if (product === undefined) return undefined;

    return {
        id: product.id,
        imageSrc: product.image,
        name: product.name,
        price: product.cost,
        volume: '',
        description: product.description,
        energy: {
            proteins: 0,
            fats: 0,
            calories: 0,
            carbohydrates: 0
        } // TODO: fetch energy data from API
    } as ProductData
}