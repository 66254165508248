import React from 'react';
import {Box, IconButton, Typography} from "@general-components/common-components";
import {ArrowForward} from "@mui/icons-material";
import useRouter from "@hooks/useRouter";

interface LinkProps {
    navigationPath: string;
}

const Link: React.FC<LinkProps> = ({navigationPath}) => {

    const { navigate } = useRouter();

    const navigationHandler = () => {
        navigate(navigationPath);
    }

    return (
        <Box alignItems={'center'} height={'100%'} onClick={navigationHandler}>
            <Typography variant={'h3'} color={'important'} fontWeight={"bolder"} display={'flex'}>
                {'Смотреть все →'}
            </Typography>
        </Box>
    )

}

export default Link;