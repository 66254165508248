import React from 'react';
import {Box, BoxImage, Button, Card, Price, Typography} from "@general-components/common-components";
import styles from './CardProduct.module.sass';
import ListItems from "@general-components/lists/ListItems";
import useRouter from "@hooks/useRouter";
import BasketButton from "@general-components/buttons/basket-button/BasketButton";
import {ProductCardData} from "@domains/Product/ProductCardData";
import ProductDefaultImage from "@images/plug/product-plug.png";

interface CardProductProps {
    product: ProductCardData,
    onClick?(args: any): void;
}

const CardProduct: React.FC<CardProductProps> = ({
                                                     product,
                                                     onClick,
}) => {

    const { navigate } = useRouter();
    const {
        productId,
        imageSrc,
        price,
        volume,
        name
    } = product

    const defaultOnClick = () => {
        if (!productId) {
            return;
        }

        navigate('/products/' + productId);
    };

    const defaultOnAddToBasket = () => {
        console.log('add to basket_old');
    };

    return (
        <Card className={styles['card-product']}>
            <Card isActive={true} onClick={onClick ?? defaultOnClick} >
                <BoxImage src={imageSrc ?? ""} defaultImage={ProductDefaultImage} height={'100px'} />
            </Card>
            <Box width={"100%"} padding={"10px"} alignItems={'stretch'}>
                <ListItems items={[
                    <Typography variant="body1" color={"primary"} >{name}</Typography>,
                    <Typography variant="body1" color={"secondary"} fontSize={'0.9rem'} >{volume}</Typography>,
                    <Price price={price ?? 0} />
                ]} />
            </Box>

            <Box width={"100%"} padding={'10px'}>
                <BasketButton productId={product.productId} />
            </Box>

        </Card>
    )
}

export default CardProduct;