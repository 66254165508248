import React from 'react';
import Card, { CardProps } from '../Card';
import styles from './CardImage.module.sass';
import Typography from '@components/general-components/typographies/Typography';
import Box from '@general-components/boxes/Box';
import { BoxImage } from '@general-components/common-components';
import CategoryImageDefault from '@images/plug/category-plug.png';

interface CardImageProps {
  title: string;
  src: string;
  alt: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  backgroundColor?: string;
}

const CardImage: React.FC<CardImageProps> = ({
  title,
  src,
  alt,
  className,
  style,
  onClick,
  backgroundColor,
}) => {
  const cardImageClassName = `${styles.card_image_container} ${className ?? ''}`;

  return (
    <Card
      isActive={true}
      onClick={onClick}
      backgroundColor={backgroundColor}
      style={{
        position: 'relative',
        overflow: 'hidden',
      }}
      withPadding={true}
    >
      <Typography variant={'h6'} color={'#1D2F49'} margin={'0'}>
        {title}
      </Typography>
      <Box className={cardImageClassName} minHeight={'70px'}>
        <BoxImage
          src={src}
          alt={alt}
          defaultImage={CategoryImageDefault}
          // style={style}
          className={styles.card_image}
        />
      </Box>
    </Card>
  );
};

export default CardImage;
