import React, {useEffect} from "react";
import {Box, CardImage, Grid, SkeletonCards, Title, Typography} from "@general-components/common-components";
import ListItems from "@general-components/lists/ListItems";
import useRouter from "@hooks/useRouter";
import {useDispatch} from "react-redux";
import {getCategories} from "../../store/category/actions";
import {CategoryData} from "@domains/Category/Category";

interface CategoriesPageProps {
    isLoading: boolean;
    isLoaded: boolean;
    categories: Array<CategoryData> | undefined;
    handleClickOnItemCategory(categoryId: string): void;
}

const CategoriesPage: React.FC<CategoriesPageProps> =
    ({
         isLoading,
         isLoaded,
         categories,
         handleClickOnItemCategory}
    ) => {

    let _body: React.ReactNode = <Box>Здесь будет что-то</Box>;

    if (isLoading) {
        _body = <Box fullwidth={true}><SkeletonCards /></Box>
    } else if (categories?.length === 0) {
        _body = <Box><Typography variant={'h4'}>Категори не найдены</Typography></Box>
    } else {
        _body =
            <Grid container spacing={10}>
                {categories?.map((item, index) => (
                    <Grid item xs={6} md={6} lg={6}>
                        <CardImage
                            alt={item.name}
                            src={item.imageSrc}
                            onClick={()=>{handleClickOnItemCategory(item.id.toString())}}
                            title={item.name}
                            backgroundColor={'#F6F6F8'}
                        />
                    </Grid>
                ))}
            </Grid>

    }

    const itemsPage = [
        <Box style={{WebkitAlignItems: 'flex-start'}}>
            <Title title={"Каталог"} />
        </Box>,
        _body
    ]

    return (
        <Box display='flex' justifyContent='flex-start' >
            <ListItems items={itemsPage}></ListItems>
        </Box>
    )
}

export default CategoriesPage;