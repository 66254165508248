import {ProductCardData} from "@domains/Product/ProductCardData";
import {
    CLEAN_RECOMMENDED_PRODUCT_USER, SET_RECOMMENDED_PRODUCT_BASKET,
    SET_RECOMMENDED_PRODUCT_PRODUCT,
    SET_RECOMMENDED_PRODUCT_USER, SET_STATE_RECOMMENDED_PRODUCT_BASKET, SET_STATE_RECOMMENDED_PRODUCT_PRODUCT,
    SET_STATE_RECOMMENDED_PRODUCT_USER
} from "./actions";

interface RecommendedState {
    isLoadingRecProduct: boolean,
    isLoadedRecProduct: boolean,
    recProductsProduct: Array<ProductCardData>,

    isLoadingRecUser: boolean,
    isLoadedRecUser: boolean,
    recProductsUser: Array<ProductCardData>,

    isLoadingRecBasket: boolean,
    isLoadedRecBasket: boolean,
    recProductsBasket: Array<ProductCardData>
}

const initialState: RecommendedState = {
    isLoadingRecProduct: false,
    isLoadedRecProduct: false,
    recProductsProduct: [],

    isLoadingRecUser: false,
    isLoadedRecUser: false,
    recProductsUser: [],

    isLoadingRecBasket: false,
    isLoadedRecBasket: false,
    recProductsBasket: []
}

const recommendedReducer = (state: RecommendedState = initialState, action: any) : RecommendedState => {
    switch (action.type) {
        case SET_RECOMMENDED_PRODUCT_USER: {

            return {
                ...state,
                isLoadingRecUser: false,
                isLoadedRecUser: true,
                recProductsUser: action.payload
            }
        }
        case SET_STATE_RECOMMENDED_PRODUCT_USER: {
            return {
               ...state,
                isLoadingRecUser: action.payload,
            }
        }
        case CLEAN_RECOMMENDED_PRODUCT_USER: {
            return {
                ...state,
                isLoadingRecUser: false,
                isLoadedRecUser: true,
                recProductsUser: []
            }
        }
        case SET_RECOMMENDED_PRODUCT_PRODUCT: {
            return {
               ...state,
                isLoadingRecProduct: false,
                isLoadedRecProduct: true,
                recProductsProduct: action.payload
            }
        }
        case SET_STATE_RECOMMENDED_PRODUCT_PRODUCT: {
            return {
               ...state,
                isLoadingRecProduct: action.payload,
            }
        }
        case SET_RECOMMENDED_PRODUCT_BASKET: {
            return {
               ...state,
                isLoadingRecBasket: false,
                isLoadedRecBasket: true,
                recProductsBasket: action.payload
            }
        }
        case SET_STATE_RECOMMENDED_PRODUCT_BASKET: {
            return {
               ...state,
                isLoadingRecBasket: action.payload,
            }
        }

        default: {
            return state;
        }
    }
}

export default recommendedReducer;