import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

interface IconEmptyBasketProps extends SvgIconProps {
    scale?: number;
}

const IconEmptyBasket: React.FC<IconEmptyBasketProps> = ({ color = '#C1C2CF', scale = 1, ...props }) => {
    return (
        <SvgIcon {...props} viewBox="0 0 168 135" sx={{ width: (100 * scale) + '%', height: (100 * scale) + '%' }}>
            <svg width="124" height="107" viewBox="0 0 124 107" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M1.86938 43H122.131L111.125 103.537C110.866 104.963 109.623 106 108.173 106H15.8266C14.3767 106 13.1343 104.963 12.875 103.537L1.86938 43Z"
                      stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M3.24475 43L47.3103 1" stroke={color} strokeWidth="2" strokeLinecap="round"
                      strokeLinejoin="round"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M120.753 43L76.6876 1L120.753 43Z" stroke={color}
                      strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M56.4918 86.377C59.5519 82.7049 64.1421 82.7049 67.5082 86.377" stroke={color}
                      strokeWidth="2" strokeLinecap="round"/>
                <circle cx="46.8516" cy="66.6393" r="5.5082" fill={color}/>
                <path d="M52.3596 57.6885C48.5039 62.095 43.4088 62.5081 39.9662 62.5081" stroke={color}
                      strokeWidth="2" strokeLinecap="round"/>
                <circle cx="5.5082" cy="5.5082" r="5.5082" transform="matrix(-1 0 0 1 79.9006 61.8196)" fill={color}/>
                <path d="M68.8841 58.377C72.7399 62.7835 77.835 63.1966 81.2776 63.1966" stroke={color}
                      strokeWidth="2" strokeLinecap="round"/>
                <path
                    d="M81.2776 77.1967C81.2776 78.2107 80.3528 79.0327 79.2121 79.0327C78.0713 79.0327 77.1465 78.2107 77.1465 77.1967C77.1465 76.1826 79.2121 73.5245 79.2121 73.5245C79.2121 73.5245 81.2776 76.1826 81.2776 77.1967Z"
                    fill={color}/>
            </svg>
        </SvgIcon>
    );
}

export default IconEmptyBasket;
