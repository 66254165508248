import React from 'react';
import {ProductCardData} from "@domains/Product/ProductCardData";
import {
    Box,
    Grid,
    Link,
    List, SkeletonCards, Title,
    Typography
} from "@general-components/common-components";
import CardProduct from "@general-components/card/card-product/CardProduct";

interface ProductsHorizontalScrollProps {
    title: string;
    products: Array<ProductCardData>;
    isLoading: boolean;
    navigatePath?: string;
}

const ProductsHorizontalScroll: React.FC<ProductsHorizontalScrollProps> =
    ({
         title,
         products,
         navigatePath,
         isLoading
    }) => {
    
    const listItems = [

        (navigatePath ? <Grid container>
            <Grid item  md={6} xs={6}>
                <Title title={title} />
            </Grid>
            <Grid item md={6} xs={6}>
                <Link navigationPath={navigatePath} />
            </Grid>
        </Grid> : <Title title={title} />),
        <Box fullwidth={true}>
            {isLoading && (<SkeletonCards countCards={2} />)}
            {!isLoading && (<Grid container spacing={10} horizontal={true}>
                    {
                        products.map((product) => (
                            <Grid item xs={6} sm={6} md={6} lg={6}>
                                <CardProduct product={product} />
                            </Grid>
                        ))
                    }
                </Grid>)
            }
        </Box>
    ]

    return (
      <Box fullwidth={true}>
          <List items={listItems} />
      </Box>
    );
}

export default ProductsHorizontalScroll;