import React, {useEffect} from 'react';
import CatalogProducts from "./CatalogProducts";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/rootReducer";
import {getProductsByCategory} from "../../store/product/actions";

interface CatalogProductsContainerProps {
    categoryId: number; // id категории, для которой хотим получить продукты. Принимает числовой аргумент.
}

const CatalogProductsContainer: React.FC<CatalogProductsContainerProps>= ({categoryId}) => {

    const disoatch = useDispatch();
    const categoryName = useSelector((state: RootState) => state.category.categories)?.find((c) => c.id === categoryId)?.name;
    const {
        isLoadedProductsCard,
        isLoadingProductsCard,
        productsCard
    } = useSelector((state: RootState) => state.product);
    useEffect(() => {
        if(categoryId) {
            disoatch(getProductsByCategory({categoryId: categoryId, limit: 100, offset: 0}));
        }
    }, []);

    return (<CatalogProducts
        title={categoryName === undefined? 'Товары' : categoryName}
        products={productsCard ?? []}
        isLoading={isLoadingProductsCard}
        isLoaded={isLoadedProductsCard}
    />);
}

export default CatalogProductsContainer;