import {call, put, select, takeEvery} from 'redux-saga/effects';
import {
    GET_PRODUCT, GET_PRODUCTS_BY_CATEGORY, SET_PRODUCTS_BY_CATEGORY,
    setProduct,
    setStateLoading,
    setStatusLoadingProductsByCategory
} from './actions';
import {convertProductFullDataDtoToInner} from "@domains/Product/Product";
import {V1GetCategoryProductsResp, V1GetProductResp} from "@domains/ServiceApi/GeneratedData";
import {ProductsByCategoryRequest} from "@domains/Category/ProductsByCategoryRequest";
import {AxiosResponse} from "axios";
import HttpStatus from "@domains/Enums/HttpStatus";
import {convertProductsDtoToInner} from "@domains/Product/ProductCardData";
import {productsApi} from "../api/recsysApi";
import {RootState} from "../rootReducer";

function* getProductsByCategory(action: any) {
    try {
        yield put(setStatusLoadingProductsByCategory(true));
        const { categoryId, offset, limit} = action.payload as ProductsByCategoryRequest;
        const response: AxiosResponse<V1GetCategoryProductsResp> =
            yield call(productsApi.categoriesCategoryIdProductsGet.bind(productsApi), categoryId, offset, limit);

        if (response.status === HttpStatus.OK) {
            const productData = convertProductsDtoToInner(response.data.data);
            yield put({type: SET_PRODUCTS_BY_CATEGORY, payload: productData});
        }
        yield put(setStatusLoadingProductsByCategory(false));
    } catch (e) {
        console.error(e);
    }
}

export function* watchGetProductsByCategory() {
    yield takeEvery(GET_PRODUCTS_BY_CATEGORY, getProductsByCategory);
}


function* getProductSaga(action: any) {
    try {
        const currentProductId: number | undefined =
            yield (select((state: RootState) => state.product.product?.id))

        if (action.payload === currentProductId?.toString()) {
            return;
        }

        yield put(setStateLoading(true));
        const product: AxiosResponse<V1GetProductResp> =
            yield call(productsApi.productsProductIdGet.bind(productsApi), action.payload);
        if (product.status === HttpStatus.OK) {
            yield put(setStateLoading(false));
            yield put(setProduct(convertProductFullDataDtoToInner(product.data.data)));
        }
    } catch (error) {
        console.error(error);
    }
}

function* watchGetProductSaga() {
    yield takeEvery(GET_PRODUCT, getProductSaga);
}

export default watchGetProductSaga;


