export const extractParams = (pathTemplate: string, actualPath: string): Record<string, string> => {
    const params: Record<string, string> = {};
    const templateSegments = pathTemplate.split('/');
    const pathSegments = actualPath.split('/');

    if (templateSegments.length !== pathSegments.length) {
        return params;
    }

    let matched = true;
    templateSegments.forEach((segment, index) => {
        if (segment.startsWith(':')) {
            const paramName = segment.slice(1);
            params[paramName] = pathSegments[index];
        } else if (segment !== pathSegments[index]) {
            matched = false;
        }
    });

    return matched ? params : {};
};