import {ProductsByCategoryRequest} from "@domains/Category/ProductsByCategoryRequest";

export const GET_CATEGORIES: string = 'GET_CATEGORIES';
export const SET_CATEGORIES: string = 'SET_CATEGORIES';
export const SET_STATUS_LOADING_CATEGORY: string = 'SET_STATUS_LOADING_CATEGORY';


export const getCategories = () => ({
    type: GET_CATEGORIES,
    payload: {},
});

export const setStatusLoadingCategory = (status: boolean) => ({
    type: SET_STATUS_LOADING_CATEGORY,
    payload: status
});