import {ProductData} from "@domains/Product/Product";
import {
    SET_PRODUCT,
    SET_PRODUCTS_BY_CATEGORY,
    SET_STATE_LOADING,
    SET_STATUS_LOADING_PRODUCTS_BY_CATEGORY
} from "./actions";
import {ProductCardData} from "@domains/Product/ProductCardData";

interface ProductState {
    isLoaded: boolean,
    isLoading: boolean,
    product: ProductData | undefined,

    isLoadingProductsCard: boolean,
    isLoadedProductsCard: boolean,
    productsCard: Array<ProductCardData> | undefined,
    currentCategoryName: string | undefined,
}

const initialState: ProductState = {
    isLoaded: false,
    isLoading: false,
    product: undefined,

    isLoadingProductsCard: false,
    isLoadedProductsCard: false,
    productsCard: undefined,
    currentCategoryName: undefined,
}

const productReducer = (state = initialState, action: any) : ProductState => {
    switch (action.type) {
        case SET_PRODUCT:
            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                product: action.payload
            }
        case SET_STATE_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            }
        case SET_PRODUCTS_BY_CATEGORY:
            return {
               ...state,
                isLoadingProductsCard: false,
                isLoadedProductsCard: true,
                productsCard: action.payload
            }

        case SET_STATUS_LOADING_PRODUCTS_BY_CATEGORY:
            return {
               ...state,
                isLoadingProductsCard: action.payload
            }
        default:
            return state;
    }
};

export default productReducer;

