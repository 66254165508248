import React from 'react';
import Skeleton from './Skeleton';

const CardSkeleton: React.FC = () => {
    return (
        <div style={{ padding: '20px', maxWidth: '300px', border: '1px solid #ddd', borderRadius: '8px' }}>
            <Skeleton width="100%" height="150px" borderRadius="8px" variant="rect" />
            <div style={{ marginTop: '10px' }}>
                <Skeleton width="60%" height="20px" variant="text" />
            </div>
            <div style={{ marginTop: '10px' }}>
                <Skeleton width="80%" height="20px" variant="text" />
            </div>
            <div style={{ marginTop: '10px' }}>
                <Skeleton width="90%" height="20px" variant="text" />
            </div>
        </div>
    );
};

export default CardSkeleton;
