import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

interface IconPhoneProps extends SvgIconProps {

}

const IconPhone: React.FC<IconPhoneProps> = ({ color = '#1D2F49', ...props }) => {
    return (
        <SvgIcon {...props} viewBox="0 0 32 33" sx={{width: '32px', height: '32px'}}>
            <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M17.2101 25.0381L20.827 21.4212C21.1697 21.0785 21.7087 21.0309 22.1062 21.3081L27.3959 24.9974C27.9065 25.3535 27.9711 26.0845 27.531 26.5247L26.3555 27.7002C25.6452 28.4104 24.6292 28.7179 23.6674 28.4281C21.0639 27.6437 15.6648 25.5974 11.238 21.1706C6.81122 16.7438 4.76494 11.3447 3.9805 8.74119C3.69073 7.77943 3.99818 6.7634 4.70844 6.05314L5.88391 4.87766C6.32409 4.43749 7.05513 4.50213 7.41124 5.01272L11.1005 10.3025C11.3778 10.6999 11.3301 11.239 10.9874 11.5816L7.37051 15.1986"
                    stroke="#3367B4" stroke-width="2" stroke-linecap="round"/>
            </svg>
        </SvgIcon>
    );
}

export default IconPhone;