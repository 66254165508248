import React, {ButtonHTMLAttributes, MouseEvent, useCallback, useState} from 'react'
import styles from './Button.module.sass';

interface Ripple {
    x: number;
    y: number;
    size: number;
}

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: 'primary' | 'outlined' | 'secondary' | 'text';
    size?: 'small' | 'medium' | 'large' | 'basket';
    fullwidth?: boolean
}

const Button: React.FC<ButtonProps> = ({
                                           variant = 'primary',
                                           children,
                                           fullwidth = false,
                                           onClick,
                                           size = 'medium',
                                           ...props }) => {

    const [rippleArray, setRippleArray] = useState<Ripple[]>([]);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        const button = event.currentTarget;
        const rect = button.getBoundingClientRect();
        const size = Math.max(rect.width, rect.height);
        const x = event.clientX - size / 2;
        const y = event.clientY - size / 2;

        const newRipple = {
            x,
            y,
            size
        };

        setRippleArray((prev) => [...prev, newRipple]);
        if (onClick) {
            onClick(event);
        }
    }



    return <button className={`
            ${styles.button} 
            ${styles[variant]} 
            ${styles[size]}
            ${fullwidth ? styles.fullwidth : ''}
        `} {...props} onClick={handleClick}>
        {rippleArray.map((ripple, index) => (
            <span
                key={index}
                className={`${styles.ripple}`}
                style={{
                    top: ripple.y,
                    left: ripple.x,
                    width: ripple.size,
                    height: ripple.size,
                }}
            />
        ))}

        {children}
    </button>
}

export default Button;