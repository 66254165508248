import React from 'react';
import styles from './ListItems.module.sass';

interface ListItemsProps {
    items: React.ReactNode[];
}

const ListItems: React.FC<ListItemsProps> = ({ items }) => {
    return (
        <ul className={styles.list}>
            {items.map((item, index) => (
                <li key={index} className={styles.item}>
                    {item}
                </li>
            ))}
        </ul>
    );
};

export default ListItems;