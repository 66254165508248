import {ProductData} from "@domains/Product/Product";
import {ProductsByCategoryRequest} from "@domains/Category/ProductsByCategoryRequest";

export const GET_PRODUCT: string = 'GET_PRODUCT';
export const SET_PRODUCT: string = 'SET_PRODUCT';
export const SET_STATE_LOADING: string = 'SET_STATE_LOADING';

export const GET_PRODUCTS_BY_CATEGORY: string = 'GET_PRODUCTS_BY_CATEGORY';
export const SET_PRODUCTS_BY_CATEGORY: string = 'SET_PRODUCTS_BY_CATEGORY';
export const SET_STATUS_LOADING_PRODUCTS_BY_CATEGORY: string = 'SET_STATUS_LOADING_PRODUCTS_BY_CATEGORY';

export const setStateLoading  = (isLoading: boolean)  =>  ({
   type: SET_STATE_LOADING,
   payload: isLoading,
});

export const getProduct = (id: number) => ({
    type: GET_PRODUCT,
    payload: id,
});

export const setProduct = (product: ProductData | undefined) => ({
    type: SET_PRODUCT,
    payload: product,
});

export const getProductsByCategory = (productsRequest: ProductsByCategoryRequest) => ({
    type: GET_PRODUCTS_BY_CATEGORY,
    payload: productsRequest,
});

export const setStatusLoadingProductsByCategory = (status: boolean) => ({
    type: SET_STATUS_LOADING_PRODUCTS_BY_CATEGORY,
    payload: status,
});