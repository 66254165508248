import {ProductBasketData} from "@domains/Product/ProductBasketData";
import {SET_BASKET_DATA} from "./actions";
import {SET_STATE_RECOMMENDED_PRODUCT_BASKET} from "../recommended/actions";

interface BasketState {
    basketItems: Array<ProductBasketData>;
    countBasketItems: number;
    isLoaded: boolean;
    isLoading: boolean;
}

const initialState: BasketState = {
    basketItems: [],
    countBasketItems: 0,
    isLoading: false,
    isLoaded: false
};

const basketReducer = (state: BasketState = initialState, action: any): BasketState => {
    switch (action.type) {
        case SET_BASKET_DATA: {
            return {
                ...state,
                basketItems: action.payload,
                countBasketItems: (action.payload as ProductBasketData[]).reduce((acc, item) => acc + item.count, 0),
                isLoading: false,
                isLoaded: true
            }
        }
        case SET_STATE_RECOMMENDED_PRODUCT_BASKET: {
            return {
               ...state,
                isLoading: action.payload
            }
        }
        default:
            return state;
    }
}

export default basketReducer;