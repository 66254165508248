import React from 'react';
import styles from './Card.module.sass';

export interface CardProps {
    children: React.ReactNode;
    className?: string;
    isActive?: boolean;
    onClick?: (args: any) => void;
    backgroundColor?: string;
    style?: React.CSSProperties;
    withPadding?: boolean;
}

const Card: React.FC<CardProps> = ({
                                       children,
                                       className ,
                                       isActive,
                                       onClick,
                                       backgroundColor,
                                       style,
                                       withPadding,
}) => {
    const cardClassName = `${styles.card_default} ${isActive ? styles.active : ''} ${withPadding? styles.with_padding : ''} ${className ?? ''}`;
    let resultStyle = {};

    if (backgroundColor) {
        resultStyle = {
            ...resultStyle,
            backgroundColor: backgroundColor,
        }
    }

    if (style) {
        resultStyle =  {...resultStyle,...style};
    }

    return <div className={cardClassName} onClick={isActive ? onClick : undefined} style={resultStyle}>{children}</div>
}

export default Card;
