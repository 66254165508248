import React, {useEffect} from "react";
import CatalogProducts from "../Catalog/CatalogProducts";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/rootReducer";
import {getRecommendedProductUser} from "../../store/recommended/actions";

const RecommendedPageContainer: React.FC = () => {
    const {
        recProductsUser,
        isLoadedRecUser,
        isLoadingRecUser
    } = useSelector((state: RootState) => state.recommended);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getRecommendedProductUser({
            limit: 100,
            offset: 0
        }));
    }, [])

    return <CatalogProducts
        title={"Рекомендуем для вас"}
        products={recProductsUser ?? []}
        isLoading={isLoadingRecUser}
        isLoaded={isLoadedRecUser}
    />
}

export default RecommendedPageContainer;