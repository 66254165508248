import React from 'react';
import {Typography} from "@general-components/common-components";

interface PriceProps {
    price: number;
}

const Price: React.FC<PriceProps> = ({ price }) => {
    return <Typography color="important" variant="h4" align="left" margin={"10px 0"}>{price} ₽</Typography>

}

export default Price;