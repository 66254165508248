import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

interface IconCircleArrowProps extends SvgIconProps {

}

const IconCircleArrow: React.FC<IconCircleArrowProps> = ({ color = '#1D2F49', ...props }) => {
    return (
        <SvgIcon {...props} viewBox="0 0 40 40" sx={{width: '100%', height: '100%'}}>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="20" cy="20" r="20" fill="white"/>
                <path d="M32 20L8 20" stroke="#1D2F49" stroke-linecap="round"/>
                <path d="M16 12L8 20L16 28" stroke="#1D2F49" stroke-linecap="round"/>
            </svg>
        </SvgIcon>
    );
}

export default IconCircleArrow;
