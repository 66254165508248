import React, {useState} from "react";
import RouterContext, {RouterContextType} from "../../contexts/route/RouterContext";
import {routes} from "../../pages/PageContent/settings";

interface RouterProviderProps {
    children: React.ReactNode;
}

const RouterProvider: React.FC<RouterProviderProps> = ({ children }) => {
    const [currentPath, setCurrentPath] = useState<string>(window.location.pathname);
    const [prev, setPrev] = useState<string | null>(null);

    const navigate = (path: string) => {
        window.history.pushState({}, '', path);
        setPrev(currentPath);
        setCurrentPath(path);
    }

    const toPrev = () => {
        if (window.history.length > 0) {
            window.history.back();
        } else {
            navigate(routes.home.path);
        }
    }

    window.onpopstate = () => {
        setCurrentPath(window.location.pathname);
    }

    const contextValue: RouterContextType = {
        currentPath,
        navigate,
        prev,
        toPrev,
    };


    return (
        <RouterContext.Provider value={contextValue}>
            {children}
        </RouterContext.Provider>
    )
}

export default RouterProvider;