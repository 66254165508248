import React from 'react';
import styles from './Product.module.sass';
import {
    Box,
    BoxImage,
    Typography,
    InlineContainer,
    IconButton, Price, ToggleText
} from '@general-components/common-components';
import {ProductData} from "@domains/Product/Product";
import useRouter from "@hooks/useRouter";
import {InlineItem} from "@general-components/inline/InlineContainer";
import BasketButton from "@general-components/buttons/basket-button/BasketButton";
import ProductPlug from "@images/plug/product-plug.png";
import {ProductCardData} from "@domains/Product/ProductCardData";
import ProductsHorizontalScroll from "@general-components/horizontal-scroll/ProductsHorizontalScroll";
import {IconCircleArrow} from "@components/icons";

interface ProductEnergyBoxProps {
    name?: number;
    type: string;
}

const ProductEnergyBox: React.FC<ProductEnergyBoxProps> = ({ name, type }) => {
    return (
        <Box className={styles['product-energy-box']}>
            <Typography variant="body1" color="primary" fontWeight={500}>
                {name ? name.toString().replace('.', ',') : ''}
            </Typography>
            <Typography variant="subtitle1" color="secondary" fontWeight={100}>
                {type}
            </Typography>
        </Box>
    );
};

interface ProductEnergyProps {
    energy?: {
        proteins?: number;
        fats?: number;
        calories?: number;
        carbohydrates?: number;
    };
}

const ProductEnergy: React.FC<ProductEnergyProps> = ({ energy }) => {
    return (
        <InlineContainer toEndRow={false}>
            <ProductEnergyBox name={energy?.calories} type="ккал" />
            <ProductEnergyBox name={energy?.proteins} type="белки" />
            <ProductEnergyBox name={energy?.fats} type="жиры" />
            <ProductEnergyBox name={energy?.carbohydrates} type="углеводы" />
        </InlineContainer>
    );
};

interface ProductProps {
    product?: ProductData;
    isLoading?: boolean;
    recommended?: ProductCardData[];
    isLoadingRecords?: boolean;
}

const ButtonBasketMemo: React.FC<ButtonBasketProdps> = React.memo(({ productId }) => {
    return (
        <BasketButton productId={productId}/>
    );
});


interface ButtonBasketProdps {
    productId: number;
}

const ProductPage: React.FC<ProductProps> = ({
                                                 product,
                                                 isLoading,
                                                 recommended,
                                                 isLoadingRecords,
}) => {
    const { toPrev } = useRouter();

    if (!product || isLoading) {
        return  (<Typography color="primary" variant="h3" align="center">Загрузка...</Typography>)
    }

    return (
        <Box className={styles['product-card']}>
            <Box display='flex' className={styles['overlay-button']} justifyContent='center' height="100%">
                <IconButton
                    icon={<IconCircleArrow />}
                    onClick={() => toPrev() }
                />
            </Box>

            <Box className={styles['product-card-images']} fullwidth={true}>
                <BoxImage src={product.imageSrc} defaultImage={ProductPlug} />
            </Box>
            <Box padding={"0px 10px 0px 10px"} fullwidth={true}>
                <Box alignItems="flex-start" fullwidth={true}>
                    <Box
                        className={styles['product-card-base-data']}
                        alignItems={"flex-start"}
                        padding={"0px 0px 0px 30px"}
                        fullwidth={true}
                    >
                        <Typography color="primary" variant="h2" align="left" margin={"20px 0px 7px 0px"}>
                            {product.name}
                        </Typography>
                        <Typography color="secondary" variant="body2" align="left">
                            {product.volume}
                        </Typography>
                        <InlineContainer toEndRow={true}>
                            <InlineItem>
                                <Price price={product.price ?? 0} />
                            </InlineItem>
                            <InlineItem>
                                <Box alignItems="flex-start" fullwidth={true} padding={"0px 20px 0px 0px"}>
                                    <ButtonBasketMemo productId={product.id} />
                                </Box>
                            </InlineItem>
                        </InlineContainer>
                    </Box>
                </Box>

                <Box fullwidth={true}>
                    {(product.description !== undefined && product.description) !== "" && (
                        <ToggleText title={"Подробнее о товаре"} text={product.description ?? ""} />
                    )}
                </Box>

                {recommended && (
                    <ProductsHorizontalScroll title={"С этим товаром покупают"} products={recommended} isLoading={isLoadingRecords ?? false}/>
                )}
            </Box>
        </Box>
    );
};

export default ProductPage;