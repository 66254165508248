import React, { FC, CSSProperties } from 'react';
import styles from './Grid.module.sass';

interface GridProps {
    container?: boolean;
    item?: boolean;
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
    spacing?: number;
    horizontal?: boolean; // Новая опция
    children?: React.ReactNode;
    className?: string;
}

const Grid: FC<GridProps> = ({
                                 container = false,
                                 item = false,
                                 xs,
                                 sm,
                                 md,
                                 lg,
                                 xl,
                                 spacing = 0,
                                 horizontal = false, // Устанавливаем значение по умолчанию
                                 children,
                                 className = '',
                                 ...rest
                             }) => {

    const getGridClass = (size: string, value: number | undefined): string => {
        if (value !== undefined) {
            return styles[`grid-${size}-${value}`];
        }
        return '';
    };

    const classes = [
        container ? styles['grid-container'] : '',
        item ? styles['grid-item'] : '',
        getGridClass('xs', xs),
        getGridClass('sm', sm ?? xs),
        getGridClass('md', md ?? sm ?? xs),
        getGridClass('lg', lg ?? md ?? sm ?? xs),
        getGridClass('xl', xl ?? lg ?? md ?? sm ?? xs),
        horizontal ? styles['grid-horizontal'] : '', // Добавляем класс для горизонтального режима
        className,
    ].join(' ').trim(); // Ensure no leading or trailing spaces in class names

    const style: CSSProperties = container
        ? { '--spacing': `${spacing}px`, width: '100%'} as CSSProperties
        : { paddingLeft: `${spacing}px`, paddingTop: `${spacing}px`, width: '100%' };

    return (
        <div className={classes} style={style} {...rest}>
            {children}
        </div>
    );
};

export default Grid;
