import {ProductBasketData} from "@domains/Product/ProductBasketData";

export const ADD_OR_PATCH_PRODUCT_TO_BASKET: string = 'ADD_OR_PATCH_PRODUCT_TO_BASKET';
export const REMOVE_PRODUCT_FROM_BASKET: string = 'REMOVE_PRODUCT_FROM_BASKET';
export const CLEAR_BASKET: string = 'CLEAR_BASKET';
export const GET_BASKET_DATA: string = 'GET_BASKET_DATA';
export const SET_BASKET_DATA: string = 'SET_BASKET_DATA';
export const SET_STATE_LOADING_BASKET: string = 'SET_STATE_LOADING_BASKET';
export const UPDATE_COUNT_PRODUCT_TO_BASKET: string = 'UPDATE_COUNT_PRODUCT_TO_BASKET';

export const getProductBasket = () => ({
    type: GET_BASKET_DATA,
    payload: {}
});

export const setBasketData = (basketData: ProductBasketData[]) => ({
    type: SET_BASKET_DATA,
    payload: basketData
});

export const addProductToBasket = (productId: number, count: number) => ({
    type: ADD_OR_PATCH_PRODUCT_TO_BASKET,
    payload: {productId, count}
});

export const removeProductFromBasket = (productId: number) => ({
    type: REMOVE_PRODUCT_FROM_BASKET,
    payload: productId
});

export const clearBasket = () => ({
    type: CLEAR_BASKET,
    payload: {}
});

export const setStatusLoading = (status: boolean) => ({
    type: SET_STATE_LOADING_BASKET,
    payload: status
});

export const updateCountProductToBasket = (productId: number, count: number) => ({
    type: UPDATE_COUNT_PRODUCT_TO_BASKET,
    payload: count
})