import React, { ReactNode, CSSProperties } from 'react';

interface InlineContainerProps {
    children: ReactNode;
    maxWidth?: string;
    gap?: string;
    toEndRow?: boolean;
    marginTop?: string;
}

const InlineContainer: React.FC<InlineContainerProps> = ({
                                                             children,
                                                             maxWidth = '100%',
                                                             gap = '8px',
                                                             toEndRow = false,
                                                             marginTop  =  undefined,
                                                         }) => {
    const containerStyle: CSSProperties = {
        display: 'flex',
        flexWrap: 'wrap',
        gap: gap,
        maxWidth: maxWidth,
        justifyContent: toEndRow ? 'space-between' : 'flex-start',
        width: toEndRow ? '100%' : undefined,
        marginTop: marginTop,
    };

    return <div style={containerStyle}>{children}</div>;
};

interface InlineItemProps {
    flex?: string;
    style?: CSSProperties;
    children: ReactNode;
}

export const InlineItem: React.FC<InlineItemProps> = ({ flex = '1', style, children }) => {
    const itemStyle: CSSProperties = {
        flex,
        height: '100%',
        ...style,
    };

    return <div style={itemStyle}>{children}</div>;
};

export default InlineContainer;