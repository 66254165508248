import React, {useCallback, useEffect} from "react";
import {Box, CardImage, Grid, Title, Typography} from "@general-components/common-components";
import ListItems from "@general-components/lists/ListItems";
import useRouter from "@hooks/useRouter";
import {useDispatch, useSelector} from "react-redux";
import {getCategories} from "../../store/category/actions";
import CategoriesPage from "./CategoriesPage";
import {RootState} from "../../store/rootReducer";

const CategoriesPageContainer: React.FC = () => {

    const { navigate } = useRouter();
    const dispatch = useDispatch();
    const { isLoading , isLoaded, categories } = useSelector((state: RootState) => state.category);
    const handleClickOnItemCategory  =  (categoryId: string)  =>  {
        navigate('/categories/' + categoryId)
    }

    const memoizedDispatch = useCallback(() => {
        dispatch(getCategories());
    }, [dispatch]);

    useEffect(() => {
        if (!isLoaded) {
            memoizedDispatch();
        }
    }, [isLoaded, memoizedDispatch]);

    return (
        <CategoriesPage
            isLoading={isLoading}
            isLoaded={isLoaded}
            categories={categories}
            handleClickOnItemCategory={handleClickOnItemCategory}
        />
    );
}

export default CategoriesPageContainer;