import React from'react';
import {CardSkeleton, Grid} from "@general-components/common-components";

type SkeletonCardProps = {
    countCards?: number;
}

const SkeletonCards: React.FC<SkeletonCardProps> = ({ countCards = 2 }) => {
    return (
        <Grid container spacing={10} horizontal={false} >
            {Array.from({ length: countCards }, (_, index) => index + 1)
                .map((item, index) => (
                <Grid item xs={6} sm={6} md={6} lg={6} key={index}>
                    <CardSkeleton/>
                </Grid>
            ))}
        </Grid>
    )
}

export default SkeletonCards;