import {createContext} from "react";

export interface RouterContextType {
    currentPath: string;
    navigate: (path: string) => void;
    prev: string | null;
    toPrev: () => void;
}

const RouterContext = createContext<RouterContextType | undefined>(undefined);

export default RouterContext;