import User from "@domains/Login/User";
import {PUT_LOGIN_DATA, PUT_LOGOUT_DATA, SET_REDIRECT_PATH} from "./actions";

interface AuthState {
    user: User | null;
    isAuth: boolean;
    isLoading: boolean;
    redirectPath: string | null;
}

const initialState: AuthState = {
    user: null,
    isAuth: false,
    isLoading: false,
    redirectPath: null,
}

const authReducer = (state = initialState, action: any): AuthState  => {
    switch (action.type) {
        case SET_REDIRECT_PATH:
            return {
               ...state,
                redirectPath: action.payload,
            }
        case PUT_LOGIN_DATA:
            return {
                ...state,
                isLoading: true,
                isAuth: true,
                user: null,
            }

        case PUT_LOGOUT_DATA:
            return {
                ...state,
                isLoading: true,
                isAuth: false,
                user: null,
            }

        default:
            return state;
    }

}

export default authReducer;