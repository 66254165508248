import React from 'react';
import {Badge, IconButton, Typography} from "@general-components/common-components";
import Box from "@general-components/boxes/Box";
import useRouter from "@hooks/useRouter";
import {useDispatch} from "react-redux";
import {changeActiveTab} from "../../../../store/settings/actions";

export interface Tab {
    path: string;
    label: string;
    withBadge: boolean;
    badgeText: string;
    icon: JSX.Element;
}

interface BottomNavigationProps {
    tabs: Tab[];
    activeTabIndex: number;
}

const BottomNavigation: React.FC<BottomNavigationProps> = ({ tabs, activeTabIndex}) => {
    const { navigate } = useRouter();
    const dispatch = useDispatch();

    const handleClick = (index: number, path: string) => {
        dispatch(changeActiveTab(index));
        navigate(path);
    }

    return (
        <Box
            display='flex'
            flexDirection='row'
            justifyContent='space-around'
            alignItems='center'
            height='100%'
        >
            {
                tabs.map((tab, index) =>
                    (tab.withBadge ?
                        <Box>
                            <Badge badgeContent={tab.badgeText}>
                                <IconButton
                                    key={index}
                                    icon={tab.icon}
                                    onClick={() => handleClick(index, tab.path)}
                                    color={index === activeTabIndex ? '#3367B4' : '#1D2F49'}
                                    children={<Typography children={tab.label} />}
                                />
                            </Badge>
                        </Box>
                        :
                        <Box>
                            <IconButton
                                key={index}
                                icon={tab.icon}
                                onClick={() => handleClick(index, tab.path)}
                                color={index === activeTabIndex ? '#3367B4' : '#1D2F49'}
                                children={<Typography children={tab.label} />}
                            />
                        </Box>)

                                )
            }
        </Box>
    )
}

export default BottomNavigation;