import React, {useEffect, useState} from 'react';
import styles from './Image.module.sass';
import {isValidImage} from "@general-components/boxes/box-image/BoxImage";

interface ImageProps {
    src: string;
    alt: string;
    height?: number | string;
    maxWidth?: string;
    maxHeight?: string;
    borderRadius?: string;
    objectFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';
    defaultImage?: any;
    className?: string;
}

const Image: React.FC<ImageProps> = ({
                                         src,
                                         alt,
                                         maxWidth,
                                         borderRadius,
                                         objectFit ,
                                         maxHeight,
                                         height = 'auto',
                                         defaultImage = "",
                                         className

}) => {
    const imageClasses = [styles.image];

    if (maxWidth === '500px') {
        imageClasses.push(styles['max-width-500']);
    }
    if (borderRadius !== undefined) {
        imageClasses.push(styles.rounded);
    }
    if (objectFit) {
        imageClasses.push(styles[objectFit]);
    }

    if (className) {
        imageClasses.push(className);
    }

    useEffect(() => {
        setImgSrc(src || defaultImage);
    }, [src]);

    const [imgSrc, setImgSrc] = useState<string>(
        src && isValidImage(src) ? src : defaultImage);


    return <img

            className={imageClasses.join(' ')}
            src={imgSrc}
            alt={alt}
            style={{maxHeight: maxHeight, height: height}}
            onError={() => setImgSrc(defaultImage)}
    />;
};

export default Image;
