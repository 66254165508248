import React from 'react';
import styled from './Badge.module.sass';

interface BadgeProps {
    badgeContent: React.ReactNode;
    children: React.ReactNode;
    color?: string;
    backgroundColor?: string;
}

const Badge: React.FC<BadgeProps> = ({ badgeContent, children, color = 'white', backgroundColor = 'red' }) => {
    return (
        <div className={styled['badge-container']}>
            {children}
            <span className={styled['badge']} style={{ color, backgroundColor }}>
                {badgeContent}
            </span>
        </div>
    );
};

export default Badge;