import './App.sass';
import React, {useEffect} from "react";
import {Box} from "@general-components/common-components";
import {RouterProvider} from "./providers";
import {Layout} from "./pages";
import {Provider} from "react-redux";
import store from "./store/index"
import {ReactNotifications} from "react-notifications-component";

const App = () => {

    useEffect(() => {
        console.warn = () => {};
        console.error = () => {};
    }, []);

    return (
        <Provider store={store}>
            <RouterProvider>
                <Box fullwidth>
                    <Layout />
                    <ReactNotifications />
                </Box>
            </RouterProvider>

        </Provider>
  );
}

export default App;
