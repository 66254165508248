import {combineReducers} from "redux";
import authReducer from './auth/reducer';
import productReducer from './product/reducer';
import categoryReducer from "./category/reducer";
import recommendedReducer from "./recommended/reducer";
import settingReducer from "./settings/reducer";
import basketReducer from "./basket/reducer";
import searchReducer from "./search/reducer";

const RootReducer = combineReducers({
    auth: authReducer,
    product: productReducer,
    basket: basketReducer,
    category: categoryReducer,
    recommended: recommendedReducer,
    settings: settingReducer,
    search: searchReducer,
});

export type RootState = ReturnType<typeof RootReducer>;
export default RootReducer;