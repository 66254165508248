import React from 'react';
import {Button, IconButton, InputField} from "@general-components/common-components";
import {Search} from "@mui/icons-material";
import HomeIcon from '@mui/icons-material/Home';
import Box from "@general-components/boxes/Box";
import Grid from "@general-components/grid/Grid";
import useRouter from "@hooks/useRouter";
import {useDispatch} from "react-redux";
import {searchProducts} from "../../../store/search/actions";
import {routes} from "../../PageContent/settings";

const HeaderWithSearch: React.FC = () => {
    const [searchQuery, setSearchQuery] = React.useState('');
    const dispatch = useDispatch();
    const { navigate } = useRouter();

    const handleSearch = () => {
        if (searchQuery !== '') {
            dispatch(searchProducts({
                searchQuery: searchQuery,
                offset: 0,
                limit: 10,
            }));

            navigate(routes.search.path);
        }
    }

    return (
        <InputField
            id={'search-products-field'}
            childrenLast={
                <IconButton icon={
                    <Search />
                }
                onClick={function (): void {
                    handleSearch();
                } } />
            }
            name={'search-products-field'}
            placeholder={'Найти продукты'}
            required={false}
            fullwidth
            size={'small'}
            value={searchQuery}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {setSearchQuery(e.target.value)}}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') {
                    handleSearch();
                }
            }}
        />

    )
    //
    // return (
    //     <Grid container>
    //         <Grid item md={2} xs={2}>
    //             <Box padding={'10px'}>
    //                 <Box
    //                     alignContent={'center'}
    //                     height={'100%'}
    //                     style={{borderRadius: '20px', border: "1px #C1C2CF solid"}}
    //                 >
    //                     <IconButton icon={<HomeIcon/>} onClick={() => {navigate('/')}} color={'rgb(51, 103, 180)'} />
    //                 </Box>
    //             </Box>
    //
    //         </Grid>
    //         <Grid item xs={10} md={10}>
    //             <InputField
    //                 id={'search-products-field'}
    //                 childrenLast={<IconButton icon={
    //                     <Search />
    //                 }
    //                                           onClick={function (): void {
    //                                               throw new Error('Function not implemented.');
    //                                           } } />}
    //                 name={'search-products-field'}
    //                 placeholder={'Найти продукты'}
    //                 required={false}
    //                 fullwidth
    //                 size={'small'}
    //                 value={''}
    //                 onChange={(e: React.ChangeEvent<HTMLInputElement>) => {console.log(e.target.value)}}
    //             />
    //         </Grid>
    //
    //     </Grid>
    // )
}

export default HeaderWithSearch;