import React from 'react';
import styled from './Footer.module.sass';

interface FooterProps {
    children: React.ReactNode;
}

const Footer: React.FC<FooterProps> = ({ children }) => {
    return <footer className={styled.footer}>{children}</footer>
}

export default Footer;