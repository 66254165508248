import {FC} from "react";
import CategoriesPage from "../Catalog/CategoriesPage";
import LoginPage from "../Auth/LoginForm/LoginPage";
import {extractParams} from "../../infrastructure/utils/extractParams";
import CatalogProducts from "../Catalog/CatalogProducts";
import ProductPageContainer from "../Product/ProductPageContainer";
import HomeContainer from "../Home/HomeContainer";
import BasketPageContainer from "../Basket/BasketPageContainer";
import CategoriesPageContainer from "../Catalog/CategoryPageContainer";
import CatalogProductsContainer from "../Catalog/CatalogProductsContainer";
import SearchPageContainer from "../Search/SearchPageContainer";
import RecommendedPageContainer from "../Recommended/RecommendedPage";

export interface RouteSetting {
    path: string;
    isProtected: boolean;
    component: FC<any>;
    backgroundColor?: string;
    flexJustifyContent?: string;
}

export let routes: { [p: string]: RouteSetting };
routes = {
    home: {
        path: '/',
        isProtected: true,
        component: HomeContainer,
        backgroundColor: '#ffffff',
    },
    login: {
        path: '/login',
        isProtected: false,
        component: LoginPage,
        backgroundColor: '#E0E8F4',
        flexJustifyContent:  'center',
    },
    account: {
        path: '/account',
        isProtected: false,
        component: LoginPage,
        flexJustifyContent:  'center',

        backgroundColor: '#E0E8F4',
    },
    catalog: {
        path: '/categories',
        isProtected: false,
        component: CategoriesPageContainer,
    },
    catalogProducts: {
        path:'/categories/:categoryId',
        isProtected: false,
        component: CatalogProductsContainer,
    },
    product: {
        path: '/products/:productId',
        isProtected: false,
        component: ProductPageContainer,
    },
    basket: {
        path: '/basket',
        isProtected: true,
        component: BasketPageContainer,
    },
    search: {
        path: '/search',
        isProtected: false,
        component: SearchPageContainer,
    },
    recommended: {
        path: '/recommended',
        isProtected: true,
        component: RecommendedPageContainer,
    }
};

export const getRouteByPath = (path: string): { route: RouteSetting | undefined, params: Record<string, string> } => {
    for (const route of Object.values(routes)) {
        const params = extractParams(route.path, path);
        if (Object.keys(params).length > 0 || route.path === path) {
            return { route, params };
        }
    }
    return { route: undefined, params: {} };
};

export const getCleanPathTemplate = function (this: string): string {
    return this.split('/').map(segment => {
        if (segment.startsWith(':')) {
            return '';
        }
        return segment;
    }).filter(segment => segment !== '').join('/');
};

declare global {
    interface String {
        getCleanPathTemplate(): string;
    }
}

String.prototype.getCleanPathTemplate = getCleanPathTemplate;
