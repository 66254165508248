import React from 'react';
import styled from './Skeleton.module.sass';
import classNames from "classnames";

type SkeletonProps = {
    width?: string;
    height?: string;
    borderRadius?: string;
    variant?: 'rect' | 'text' | 'circle';
};

const Skeleton: React.FC<SkeletonProps> = ({ width, height, borderRadius, variant = 'rect' }) => {
    const skeletonClass = classNames(styled.skeleton, styled[`skeleton-${variant}`]);

    const inlineStyles = {
        width: width || '100%',
        height: height || (variant === 'circle' ? width : '20px'),
        borderRadius: borderRadius || (variant === 'circle' ? '50%' : '4px'),
    };

    return <div className={skeletonClass} style={inlineStyles}></div>;
};

export default Skeleton;
