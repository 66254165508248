import React from 'react';
import classNames from 'classnames'; // Импортируем функцию для объединения классов
import styled from './Header.module.sass';

interface HeaderProps {
    children?: React.ReactNode;
    className?: string; // Добавляем новый пропс className
}

const Header: React.FC<HeaderProps> = ({
                                           children,
                                           className // Деструктурируем новый пропс className
                                       }) => (
    <header className={classNames(styled.header, className)}>
        {children}
    </header>
);

export default Header;