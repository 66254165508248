import {convertProductDtoToInner, ProductCardData} from "@domains/Product/ProductCardData";
import {DomainCartProduct} from "@domains/ServiceApi/GeneratedData";

export interface ProductBasketData {
    product: ProductCardData;
    count: number;
}

export type ConvertProductBasketDtoToInner = (product: DomainCartProduct | undefined) => ProductBasketData | undefined;

export const convertProductBasketDtoToInner: ConvertProductBasketDtoToInner = (product: DomainCartProduct | undefined) => {
    if (product === undefined) return undefined;

    return {
        product: convertProductDtoToInner(product.product),
        count: product.count?? 0
    } as ProductBasketData;
}

export const convertProductsDtoToInner = (products: Array<DomainCartProduct> | undefined): ProductBasketData[] | undefined => {
    if (products === undefined) return undefined;

    return products.map(convertProductBasketDtoToInner).filter((productData): productData is ProductBasketData => productData !== undefined);
}