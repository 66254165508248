import {CHANGE_ACTIVE_TAB} from "./actions";

interface SettingState {
    activeBottomTabIndex: number
}

const initialState: SettingState = {
    activeBottomTabIndex: -1
};

const settingReducer = (state: SettingState = initialState, action: any): SettingState => {
    switch (action.type) {
        case CHANGE_ACTIVE_TAB:
            return {
               ...state,
                activeBottomTabIndex: action.payload
            }
        default:
            return state;
    }
}

export default settingReducer;