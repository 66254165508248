import React, {ReactElement, useEffect, useState} from 'react'
import styles from './LoginForm.module.sass';
import {Box, Button, Card, InputField, Typography} from "@general-components/common-components";
import Login from "../../../domain/Login/Login";
import {useDispatch, useSelector} from "react-redux";
import {login} from "../../../store/auth/actions";
import useRouter from "@hooks/useRouter";
import {RootState} from "../../../store/rootReducer";
import {getProductBasket} from "../../../store/basket/actions";

/**
 * Renders the login form component.
 *
 * @return {ReactElement} The rendered login form component.
 */
const LoginForm: React.FC = (): ReactElement => {
    const [loginData, setLoginData] = useState<Login>({ name: '', password: '' });
    const { isAuth, redirectPath } = useSelector((state: RootState) => state.auth);
    const dispatch = useDispatch();

    const { navigate } = useRouter();

    useEffect(() => {
        if (isAuth) {
            const path = redirectPath || '/';
            navigate(path);
            dispatch(getProductBasket());
        }
    }, [isAuth]);

    const changeLogin = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLoginData( {
            name: e.currentTarget.value,
            password: e.currentTarget.value
        })
    }

    const handleLogin = async () => {
        const success = await dispatch(login(loginData));
    }

    return (
        <Card withPadding={true} backgroundColor={'white'}>
            <Box
                className={`${styles.login_form}`}
                display='flex'
                flexDirection='column'
                alignItems='center'
                justifyContent='center'
            >
                <Typography 
                    variant='h2' 
                    color='primary'>
                        Вход
                </Typography>
                <InputField
                    id='login'
                    name='id пользователя'
                    placeholder='id пользователя'
                    required={true}
                    fullwidth={true}
                    onChange={changeLogin}
                    value={loginData.name}
                />
                <Button
                    variant='primary'
                    onClick={handleLogin}
                >
                    Войти
                </Button>
            </Box>
        </Card>
    )
}

export default LoginForm;