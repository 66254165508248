import React from 'react';
import styles from './Box.module.sass';

interface BoxProps {
    className?: string;
    display?: 'flex' | 'block' | 'inline-block' | 'inline' | 'table' | 'table-row' | 'table-cell' | 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch' | 'initial' | 'inherit';
    flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse' | 'initial' | 'inherit';
    flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse' | 'initial' | 'inherit';
    justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly' | 'initial' | 'inherit' | string;
    alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch' | 'initial' | 'inherit';
    alignContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'stretch' | 'initial' | 'inherit';
    height?: string;
    width?: string;
    maxHeight?: string;
    maxWidth?: string;
    minHeight?: string;
    minWidth?: string;
    backgroundImage?: string | File;
    backgroundSize?: 'cover' | 'contain' | 'auto' | string;
    backgroundPosition?: 'center' | 'top' | 'bottom' | 'left' | 'right' | string;
    backgroundRepeat?: 'no-repeat' | 'repeat' | 'repeat-x' | 'repeat-y' | 'initial' | 'inherit';
    flexShrink?: number;
    flexGrow?: number; // Новый пропс
    children: React.ReactNode;
    style?: React.CSSProperties;
    fullwidth?: boolean;
    padding?: string;
    onClick?(args: any): void;
    margin?: string;
}

const Box: React.FC<BoxProps> = ({
                                     children,
                                     className,
                                     style,
                                     display,
                                     flexDirection,
                                     flexWrap,
                                     justifyContent,
                                     alignItems,
                                     alignContent,
                                     height,
                                     width,
                                     minHeight,
                                     minWidth,
                                     maxHeight,
                                     maxWidth,
                                     backgroundImage,
                                     backgroundSize,
                                     backgroundPosition,
                                     backgroundRepeat,
                                     flexShrink,
                                     flexGrow, // Новый пропс
                                     fullwidth,
                                     padding,
                                     onClick,
                                     margin
                                 }) => {
    const classNames = className ? `${styles.box} ${className}` : styles.box;

    const inputWidth = fullwidth ? '100%' : width;

    const resolvedBackgroundImage = backgroundImage instanceof File
        ? `url(${URL.createObjectURL(backgroundImage)})`
        : `url(${backgroundImage})`;

    const containerStyle: React.CSSProperties = {
        display,
        flexDirection,
        flexWrap,
        justifyContent,
        alignItems,
        alignContent,
        height,
        width: inputWidth,
        minHeight,
        minWidth,
        maxHeight,
        maxWidth,
        flexShrink,
        flexGrow, // Применение flexGrow
        padding,
        backgroundImage: resolvedBackgroundImage,
        backgroundSize,
        backgroundPosition,
        backgroundRepeat,
        boxSizing: 'border-box',
        cursor: onClick === undefined ? "default" : "pointer",
        ...style,
    };

    if (margin) {
        containerStyle.margin = margin;
    }

    if (alignContent === 'flex-start') {
        containerStyle.WebkitAlignItems = 'flex-start';
    }

    return (
        <div className={classNames} style={containerStyle} onClick={onClick}>
            {children}
        </div>
    );
};

export default Box;
