import {SearchRequestByCategoryDto, SearchRequestDto} from "@domains/Search/SearchRequestDto";
import {ProductCardData} from "@domains/Product/ProductCardData";

export const SEARCH_PRODUCTS: string = 'SEARCH_PRODUCTS';
export const SEARCH_PRODUCTS_BY_CATEGORY: string = 'SEARCH_PRODUCTS_BY_CATEGORY';
export const SET_SEARCH_PRODUCTS: string = 'SET_SEARCH_PRODUCTS';
export const SET_STATUS_SEARCH_PRODUCTS: string = 'SET_STATUS_SEARCH_PRODUCTS';
export const SET_STATUS_LOADED_SEARCH_PRODUCTS: string = 'SET_STATUS_LOADED_SEARCH_PRODUCTS';
export const CLEAN_SEARCH_PRODUCTS: string = 'CLEAN_SEARCH_PRODUCTS';
export const SET_QUERY_SEARCH_PRODUCTS: string = 'SET_QUERY_SEARCH_PRODUCTS';

export const setQuerySearchProducts = (query: string) => ({
    type: SET_QUERY_SEARCH_PRODUCTS,
    payload: query,
});

export const searchProducts = (query: SearchRequestDto) => ({
    type: SEARCH_PRODUCTS,
    payload: query,
});

export const searchProductsByCategory = (query: SearchRequestByCategoryDto) => ({
    type: SEARCH_PRODUCTS_BY_CATEGORY,
    payload: query,
});

export const setStatusLoadingSearchProducts = (status: boolean) => ({
    type: SET_STATUS_SEARCH_PRODUCTS,
    payload: status,
});

export const setStatusLoadedSearchProducts = (status: boolean) => ({
    type: SET_STATUS_LOADED_SEARCH_PRODUCTS,
    payload: status,
});

export const cleanSearchProducts = () => ({
    type: CLEAN_SEARCH_PRODUCTS,
    payload: {},
});

export const setSearchProducts = (query: ProductCardData[]) => ({
    type: SET_SEARCH_PRODUCTS,
    payload: query,
});