import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

interface IconGeolocationProps extends SvgIconProps {

}

const IconGeolocation: React.FC<IconGeolocationProps> = ({ color = '#1D2F49', ...props }) => {
    return (
        <SvgIcon {...props} viewBox="0 0 32 33" sx={{width: '32px', height: '32px'}}>
            <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M16 28.5C16 28.5 24 20.1 24 12.5C24 8.08172 20.4183 4.5 16 4.5C11.5817 4.5 8 8.08172 8 12.5C8 20.1 16 28.5 16 28.5Z"
                      stroke="#3367B4" stroke-width="2"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M16 13.8333C16.7363 13.8333 17.3333 13.2364 17.3333 12.5C17.3333 11.7636 16.7363 11.1667 16 11.1667C15.2636 11.1667 14.6666 11.7636 14.6666 12.5C14.6666 13.2364 15.2636 13.8333 16 13.8333Z"
                      stroke="#3367B4" stroke-width="2"/>
            </svg>
        </SvgIcon>
    );
}

export default IconGeolocation;