/* tslint:disable */
/* eslint-disable */
/**
 * Recsys demo API
 * Сервис демонстрации рекомендательной системы
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface DomainCartProduct
 */
export interface DomainCartProduct {
    /**
     * 
     * @type {number}
     * @memberof DomainCartProduct
     */
    'count'?: number;
    /**
     * 
     * @type {DomainProduct}
     * @memberof DomainCartProduct
     */
    'product'?: DomainProduct;
}
/**
 * 
 * @export
 * @interface DomainCartSuggestion
 */
export interface DomainCartSuggestion {
    /**
     * 
     * @type {DomainProduct}
     * @memberof DomainCartSuggestion
     */
    'product'?: DomainProduct;
}
/**
 * 
 * @export
 * @interface DomainCategory
 */
export interface DomainCategory {
    /**
     * 
     * @type {number}
     * @memberof DomainCategory
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DomainCategory
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof DomainCategory
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface DomainProduct
 */
export interface DomainProduct {
    /**
     * 
     * @type {DomainCategory}
     * @memberof DomainProduct
     */
    'category'?: DomainCategory;
    /**
     * 
     * @type {number}
     * @memberof DomainProduct
     */
    'cost'?: number;
    /**
     * 
     * @type {string}
     * @memberof DomainProduct
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof DomainProduct
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DomainProduct
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof DomainProduct
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface DomainProductSuggestion
 */
export interface DomainProductSuggestion {
    /**
     * 
     * @type {DomainProduct}
     * @memberof DomainProductSuggestion
     */
    'product'?: DomainProduct;
    /**
     * 
     * @type {number}
     * @memberof DomainProductSuggestion
     */
    'user_id'?: number;
}
/**
 * 
 * @export
 * @interface DomainUserSuggestion
 */
export interface DomainUserSuggestion {
    /**
     * 
     * @type {DomainProduct}
     * @memberof DomainUserSuggestion
     */
    'product'?: DomainProduct;
}
/**
 * 
 * @export
 * @interface V1AddProductToCartRequest
 */
export interface V1AddProductToCartRequest {
    /**
     * 
     * @type {number}
     * @memberof V1AddProductToCartRequest
     */
    'product_id'?: number;
}
/**
 * 
 * @export
 * @interface V1ErrorResp
 */
export interface V1ErrorResp {
    /**
     * 
     * @type {number}
     * @memberof V1ErrorResp
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof V1ErrorResp
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface V1GetCartResponse
 */
export interface V1GetCartResponse {
    /**
     * 
     * @type {Array<DomainCartProduct>}
     * @memberof V1GetCartResponse
     */
    'data'?: Array<DomainCartProduct>;
}
/**
 * 
 * @export
 * @interface V1GetCartSuggestionsResp
 */
export interface V1GetCartSuggestionsResp {
    /**
     * 
     * @type {Array<DomainCartSuggestion>}
     * @memberof V1GetCartSuggestionsResp
     */
    'data'?: Array<DomainCartSuggestion>;
}
/**
 * 
 * @export
 * @interface V1GetCategoriesResp
 */
export interface V1GetCategoriesResp {
    /**
     * 
     * @type {Array<DomainCategory>}
     * @memberof V1GetCategoriesResp
     */
    'data'?: Array<DomainCategory>;
}
/**
 * 
 * @export
 * @interface V1GetCategoryProductsResp
 */
export interface V1GetCategoryProductsResp {
    /**
     * 
     * @type {Array<DomainProduct>}
     * @memberof V1GetCategoryProductsResp
     */
    'data'?: Array<DomainProduct>;
}
/**
 * 
 * @export
 * @interface V1GetProductResp
 */
export interface V1GetProductResp {
    /**
     * 
     * @type {DomainProduct}
     * @memberof V1GetProductResp
     */
    'data'?: DomainProduct;
}
/**
 * 
 * @export
 * @interface V1GetProductSuggestionsResp
 */
export interface V1GetProductSuggestionsResp {
    /**
     * 
     * @type {Array<DomainProductSuggestion>}
     * @memberof V1GetProductSuggestionsResp
     */
    'data'?: Array<DomainProductSuggestion>;
}
/**
 * 
 * @export
 * @interface V1GetUserSuggestionsResp
 */
export interface V1GetUserSuggestionsResp {
    /**
     * 
     * @type {Array<DomainUserSuggestion>}
     * @memberof V1GetUserSuggestionsResp
     */
    'data'?: Array<DomainUserSuggestion>;
}
/**
 * 
 * @export
 * @interface V1LoginRequest
 */
export interface V1LoginRequest {
    /**
     * 
     * @type {number}
     * @memberof V1LoginRequest
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface V1SearchCategoryProductsResp
 */
export interface V1SearchCategoryProductsResp {
    /**
     * 
     * @type {Array<DomainProduct>}
     * @memberof V1SearchCategoryProductsResp
     */
    'data'?: Array<DomainProduct>;
}
/**
 * 
 * @export
 * @interface V1SearchProductsResp
 */
export interface V1SearchProductsResp {
    /**
     * 
     * @type {Array<DomainProduct>}
     * @memberof V1SearchProductsResp
     */
    'data'?: Array<DomainProduct>;
}

/**
 * CategoriesProductsApi - axios parameter creator
 * @export
 */
export const CategoriesProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Возвращает список продуктов конкретной категории
         * @param {number} categoryId Category ID
         * @param {number} offset Offset
         * @param {number} limit Кол-во возвращаемых товаров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesCategoryIdProductsGet: async (categoryId: number, offset: number, limit: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('categoriesCategoryIdProductsGet', 'categoryId', categoryId)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('categoriesCategoryIdProductsGet', 'offset', offset)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('categoriesCategoryIdProductsGet', 'limit', limit)
            const localVarPath = `/categories/{categoryId}/products`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Ищет продукты
         * @param {string} search Название товара
         * @param {number} categoryId Category ID
         * @param {number} offset Offset
         * @param {number} limit Кол-во возвращаемых товаров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesCategoryIdProductsSearchGet: async (search: string, categoryId: number, offset: number, limit: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'search' is not null or undefined
            assertParamExists('categoriesCategoryIdProductsSearchGet', 'search', search)
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('categoriesCategoryIdProductsSearchGet', 'categoryId', categoryId)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('categoriesCategoryIdProductsSearchGet', 'offset', offset)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('categoriesCategoryIdProductsSearchGet', 'limit', limit)
            const localVarPath = `/categories/{categoryId}/products/search`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Возвращает список категорий товаров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Возвращает карточку продукта
         * @param {number} productId Product ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsProductIdGet: async (productId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('productsProductIdGet', 'productId', productId)
            const localVarPath = `/products/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Возвращает рекомендации товаров для товара
         * @param {number} productId Product ID
         * @param {number} count Кол-во возвращаемых товаров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsProductIdRecommendationsGet: async (productId: number, count: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('productsProductIdRecommendationsGet', 'productId', productId)
            // verify required parameter 'count' is not null or undefined
            assertParamExists('productsProductIdRecommendationsGet', 'count', count)
            const localVarPath = `/products/{productId}/recommendations`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Ищет продукты
         * @param {string} search Название товара
         * @param {number} offset Offset
         * @param {number} limit Кол-во возвращаемых товаров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsSearchGet: async (search: string, offset: number, limit: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'search' is not null or undefined
            assertParamExists('productsSearchGet', 'search', search)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('productsSearchGet', 'offset', offset)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('productsSearchGet', 'limit', limit)
            const localVarPath = `/products/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoriesProductsApi - functional programming interface
 * @export
 */
export const CategoriesProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CategoriesProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Возвращает список продуктов конкретной категории
         * @param {number} categoryId Category ID
         * @param {number} offset Offset
         * @param {number} limit Кол-во возвращаемых товаров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesCategoryIdProductsGet(categoryId: number, offset: number, limit: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1GetCategoryProductsResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesCategoryIdProductsGet(categoryId, offset, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CategoriesProductsApi.categoriesCategoryIdProductsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Ищет продукты
         * @param {string} search Название товара
         * @param {number} categoryId Category ID
         * @param {number} offset Offset
         * @param {number} limit Кол-во возвращаемых товаров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesCategoryIdProductsSearchGet(search: string, categoryId: number, offset: number, limit: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1SearchCategoryProductsResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesCategoryIdProductsSearchGet(search, categoryId, offset, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CategoriesProductsApi.categoriesCategoryIdProductsSearchGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Возвращает список категорий товаров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1GetCategoriesResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CategoriesProductsApi.categoriesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Возвращает карточку продукта
         * @param {number} productId Product ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsProductIdGet(productId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1GetProductResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsProductIdGet(productId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CategoriesProductsApi.productsProductIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Возвращает рекомендации товаров для товара
         * @param {number} productId Product ID
         * @param {number} count Кол-во возвращаемых товаров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsProductIdRecommendationsGet(productId: number, count: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1GetProductSuggestionsResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsProductIdRecommendationsGet(productId, count, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CategoriesProductsApi.productsProductIdRecommendationsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Ищет продукты
         * @param {string} search Название товара
         * @param {number} offset Offset
         * @param {number} limit Кол-во возвращаемых товаров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsSearchGet(search: string, offset: number, limit: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1SearchProductsResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsSearchGet(search, offset, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CategoriesProductsApi.productsSearchGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CategoriesProductsApi - factory interface
 * @export
 */
export const CategoriesProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CategoriesProductsApiFp(configuration)
    return {
        /**
         * 
         * @summary Возвращает список продуктов конкретной категории
         * @param {number} categoryId Category ID
         * @param {number} offset Offset
         * @param {number} limit Кол-во возвращаемых товаров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesCategoryIdProductsGet(categoryId: number, offset: number, limit: number, options?: RawAxiosRequestConfig): AxiosPromise<V1GetCategoryProductsResp> {
            return localVarFp.categoriesCategoryIdProductsGet(categoryId, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Ищет продукты
         * @param {string} search Название товара
         * @param {number} categoryId Category ID
         * @param {number} offset Offset
         * @param {number} limit Кол-во возвращаемых товаров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesCategoryIdProductsSearchGet(search: string, categoryId: number, offset: number, limit: number, options?: RawAxiosRequestConfig): AxiosPromise<V1SearchCategoryProductsResp> {
            return localVarFp.categoriesCategoryIdProductsSearchGet(search, categoryId, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Возвращает список категорий товаров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesGet(options?: RawAxiosRequestConfig): AxiosPromise<V1GetCategoriesResp> {
            return localVarFp.categoriesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Возвращает карточку продукта
         * @param {number} productId Product ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsProductIdGet(productId: number, options?: RawAxiosRequestConfig): AxiosPromise<V1GetProductResp> {
            return localVarFp.productsProductIdGet(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Возвращает рекомендации товаров для товара
         * @param {number} productId Product ID
         * @param {number} count Кол-во возвращаемых товаров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsProductIdRecommendationsGet(productId: number, count: number, options?: RawAxiosRequestConfig): AxiosPromise<V1GetProductSuggestionsResp> {
            return localVarFp.productsProductIdRecommendationsGet(productId, count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Ищет продукты
         * @param {string} search Название товара
         * @param {number} offset Offset
         * @param {number} limit Кол-во возвращаемых товаров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsSearchGet(search: string, offset: number, limit: number, options?: RawAxiosRequestConfig): AxiosPromise<V1SearchProductsResp> {
            return localVarFp.productsSearchGet(search, offset, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CategoriesProductsApi - object-oriented interface
 * @export
 * @class CategoriesProductsApi
 * @extends {BaseAPI}
 */
export class CategoriesProductsApi extends BaseAPI {
    /**
     * 
     * @summary Возвращает список продуктов конкретной категории
     * @param {number} categoryId Category ID
     * @param {number} offset Offset
     * @param {number} limit Кол-во возвращаемых товаров
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesProductsApi
     */
    public categoriesCategoryIdProductsGet(categoryId: number, offset: number, limit: number, options?: RawAxiosRequestConfig) {
        return CategoriesProductsApiFp(this.configuration).categoriesCategoryIdProductsGet(categoryId, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Ищет продукты
     * @param {string} search Название товара
     * @param {number} categoryId Category ID
     * @param {number} offset Offset
     * @param {number} limit Кол-во возвращаемых товаров
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesProductsApi
     */
    public categoriesCategoryIdProductsSearchGet(search: string, categoryId: number, offset: number, limit: number, options?: RawAxiosRequestConfig) {
        return CategoriesProductsApiFp(this.configuration).categoriesCategoryIdProductsSearchGet(search, categoryId, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Возвращает список категорий товаров
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesProductsApi
     */
    public categoriesGet(options?: RawAxiosRequestConfig) {
        console.log(`-----------------------Base Path Set To: ${this.basePath}`);
        return CategoriesProductsApiFp(this.configuration).categoriesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Возвращает карточку продукта
     * @param {number} productId Product ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesProductsApi
     */
    public productsProductIdGet(productId: number, options?: RawAxiosRequestConfig) {
        return CategoriesProductsApiFp(this.configuration).productsProductIdGet(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Возвращает рекомендации товаров для товара
     * @param {number} productId Product ID
     * @param {number} count Кол-во возвращаемых товаров
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesProductsApi
     */
    public productsProductIdRecommendationsGet(productId: number, count: number, options?: RawAxiosRequestConfig) {
        return CategoriesProductsApiFp(this.configuration).productsProductIdRecommendationsGet(productId, count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Ищет продукты
     * @param {string} search Название товара
     * @param {number} offset Offset
     * @param {number} limit Кол-во возвращаемых товаров
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesProductsApi
     */
    public productsSearchGet(search: string, offset: number, limit: number, options?: RawAxiosRequestConfig) {
        return CategoriesProductsApiFp(this.configuration).productsSearchGet(search, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DatasetApi - axios parameter creator
 * @export
 */
export const DatasetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Ручка принимает на вход 4 файла: users_suggestions.csv, categories.csv, products.csv, orders.csv.
         * @summary Загружает датасет
         * @param {File} files файлы датасета, свагер не позволяет прикреплять больше 1 файла, поэтому дергайте через постман и прочее
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasetUploadPost: async (files: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'files' is not null or undefined
            assertParamExists('datasetUploadPost', 'files', files)
            const localVarPath = `/dataset/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (files !== undefined) { 
                localVarFormParams.append('files', files as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DatasetApi - functional programming interface
 * @export
 */
export const DatasetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DatasetApiAxiosParamCreator(configuration)
    return {
        /**
         * Ручка принимает на вход 4 файла: users_suggestions.csv, categories.csv, products.csv, orders.csv.
         * @summary Загружает датасет
         * @param {File} files файлы датасета, свагер не позволяет прикреплять больше 1 файла, поэтому дергайте через постман и прочее
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async datasetUploadPost(files: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.datasetUploadPost(files, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DatasetApi.datasetUploadPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DatasetApi - factory interface
 * @export
 */
export const DatasetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DatasetApiFp(configuration)
    return {
        /**
         * Ручка принимает на вход 4 файла: users_suggestions.csv, categories.csv, products.csv, orders.csv.
         * @summary Загружает датасет
         * @param {File} files файлы датасета, свагер не позволяет прикреплять больше 1 файла, поэтому дергайте через постман и прочее
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasetUploadPost(files: File, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.datasetUploadPost(files, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DatasetApi - object-oriented interface
 * @export
 * @class DatasetApi
 * @extends {BaseAPI}
 */
export class DatasetApi extends BaseAPI {
    /**
     * Ручка принимает на вход 4 файла: users_suggestions.csv, categories.csv, products.csv, orders.csv.
     * @summary Загружает датасет
     * @param {File} files файлы датасета, свагер не позволяет прикреплять больше 1 файла, поэтому дергайте через постман и прочее
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApi
     */
    public datasetUploadPost(files: File, options?: RawAxiosRequestConfig) {
        return DatasetApiFp(this.configuration).datasetUploadPost(files, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Авторизует пользователя
         * @param {V1LoginRequest} request Login request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginPost: async (request: V1LoginRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('loginPost', 'request', request)
            const localVarPath = `/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Возвращает корзину
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCartGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/cart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Добавляет товар в корзину
         * @param {V1AddProductToCartRequest} request Add product to cart request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCartProductPost: async (request: V1AddProductToCartRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('userCartProductPost', 'request', request)
            const localVarPath = `/user/cart/product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаляет товар из корзины
         * @param {number} productId ID of product to add
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCartProductProductIdDelete: async (productId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('userCartProductProductIdDelete', 'productId', productId)
            const localVarPath = `/user/cart/product/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменяет кол-во товара в корзине
         * @param {number} productId ID of product to update
         * @param {number} count count of product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCartProductProductIdPatch: async (productId: number, count: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('userCartProductProductIdPatch', 'productId', productId)
            // verify required parameter 'count' is not null or undefined
            assertParamExists('userCartProductProductIdPatch', 'count', count)
            const localVarPath = `/user/cart/product/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Возвращает рекомендации товаров для корзины
         * @param {number} count Кол-во возвращаемых товаров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCartRecommendationsGet: async (count: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'count' is not null or undefined
            assertParamExists('userCartRecommendationsGet', 'count', count)
            const localVarPath = `/user/cart/recommendations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Разлогинивает пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userLogoutPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Возвращает рекомендации товаров для юзера
         * @param {number} offset Сдвиг
         * @param {number} limit Кол-во возвращаемых товаров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRecommendationsGet: async (offset: number, limit: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('userRecommendationsGet', 'offset', offset)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('userRecommendationsGet', 'limit', limit)
            const localVarPath = `/user/recommendations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Авторизует пользователя
         * @param {V1LoginRequest} request Login request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginPost(request: V1LoginRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginPost(request, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.loginPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Возвращает корзину
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userCartGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1GetCartResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userCartGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userCartGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Добавляет товар в корзину
         * @param {V1AddProductToCartRequest} request Add product to cart request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userCartProductPost(request: V1AddProductToCartRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1GetCartResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userCartProductPost(request, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userCartProductPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Удаляет товар из корзины
         * @param {number} productId ID of product to add
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userCartProductProductIdDelete(productId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1GetCartResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userCartProductProductIdDelete(productId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userCartProductProductIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Изменяет кол-во товара в корзине
         * @param {number} productId ID of product to update
         * @param {number} count count of product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userCartProductProductIdPatch(productId: number, count: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1GetCartResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userCartProductProductIdPatch(productId, count, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userCartProductProductIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Возвращает рекомендации товаров для корзины
         * @param {number} count Кол-во возвращаемых товаров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userCartRecommendationsGet(count: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1GetCartSuggestionsResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userCartRecommendationsGet(count, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userCartRecommendationsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Разлогинивает пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userLogoutPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userLogoutPost(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userLogoutPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Возвращает рекомендации товаров для юзера
         * @param {number} offset Сдвиг
         * @param {number} limit Кол-во возвращаемых товаров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRecommendationsGet(offset: number, limit: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1GetUserSuggestionsResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userRecommendationsGet(offset, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userRecommendationsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary Авторизует пользователя
         * @param {V1LoginRequest} request Login request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginPost(request: V1LoginRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.loginPost(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Возвращает корзину
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCartGet(options?: RawAxiosRequestConfig): AxiosPromise<V1GetCartResponse> {
            return localVarFp.userCartGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Добавляет товар в корзину
         * @param {V1AddProductToCartRequest} request Add product to cart request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCartProductPost(request: V1AddProductToCartRequest, options?: RawAxiosRequestConfig): AxiosPromise<V1GetCartResponse> {
            return localVarFp.userCartProductPost(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удаляет товар из корзины
         * @param {number} productId ID of product to add
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCartProductProductIdDelete(productId: number, options?: RawAxiosRequestConfig): AxiosPromise<V1GetCartResponse> {
            return localVarFp.userCartProductProductIdDelete(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Изменяет кол-во товара в корзине
         * @param {number} productId ID of product to update
         * @param {number} count count of product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCartProductProductIdPatch(productId: number, count: number, options?: RawAxiosRequestConfig): AxiosPromise<V1GetCartResponse> {
            return localVarFp.userCartProductProductIdPatch(productId, count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Возвращает рекомендации товаров для корзины
         * @param {number} count Кол-во возвращаемых товаров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCartRecommendationsGet(count: number, options?: RawAxiosRequestConfig): AxiosPromise<V1GetCartSuggestionsResp> {
            return localVarFp.userCartRecommendationsGet(count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Разлогинивает пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userLogoutPost(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.userLogoutPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Возвращает рекомендации товаров для юзера
         * @param {number} offset Сдвиг
         * @param {number} limit Кол-во возвращаемых товаров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRecommendationsGet(offset: number, limit: number, options?: RawAxiosRequestConfig): AxiosPromise<V1GetUserSuggestionsResp> {
            return localVarFp.userRecommendationsGet(offset, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Авторизует пользователя
     * @param {V1LoginRequest} request Login request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public loginPost(request: V1LoginRequest, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).loginPost(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Возвращает корзину
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userCartGet(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userCartGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Добавляет товар в корзину
     * @param {V1AddProductToCartRequest} request Add product to cart request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userCartProductPost(request: V1AddProductToCartRequest, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userCartProductPost(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удаляет товар из корзины
     * @param {number} productId ID of product to add
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userCartProductProductIdDelete(productId: number, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userCartProductProductIdDelete(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Изменяет кол-во товара в корзине
     * @param {number} productId ID of product to update
     * @param {number} count count of product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userCartProductProductIdPatch(productId: number, count: number, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userCartProductProductIdPatch(productId, count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Возвращает рекомендации товаров для корзины
     * @param {number} count Кол-во возвращаемых товаров
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userCartRecommendationsGet(count: number, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userCartRecommendationsGet(count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Разлогинивает пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userLogoutPost(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userLogoutPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Возвращает рекомендации товаров для юзера
     * @param {number} offset Сдвиг
     * @param {number} limit Кол-во возвращаемых товаров
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userRecommendationsGet(offset: number, limit: number, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userRecommendationsGet(offset, limit, options).then((request) => request(this.axios, this.basePath));
    }
}



