import React, {useCallback, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProduct } from '../../store/product/actions';
import { RootState } from '../../store/rootReducer';
import ProductPage from './ProductPage';
import {getRecommendedProductProduct} from "../../store/recommended/actions";

interface ProductPageContainerProps {
    productId: number;
}

const ProductPageContainer: React.FC<ProductPageContainerProps> = ({ productId }) => {
    const dispatch = useDispatch();
    const { product, isLoading, isLoaded } = useSelector((state: RootState) => state.product);
    const { recProductsProduct, isLoadingRecProduct } = useSelector((state: RootState) => state.recommended)

    const fetchProduct = useCallback(() => {
        dispatch(getProduct(productId));
        dispatch(getRecommendedProductProduct(productId, 10));
    }, [dispatch, productId]);

    useEffect(() => {
        fetchProduct();
    }, [fetchProduct]);

    return <ProductPage
        product={product}
        isLoading={isLoading}
        recommended={recProductsProduct}
        isLoadingRecords={isLoadingRecProduct}
    />;
}

export default ProductPageContainer;