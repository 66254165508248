import {DomainProduct} from "@domains/ServiceApi/GeneratedData";
export interface ProductCardData {
    productId: number;
    imageSrc: string;
    name: string;
    volume: string;
    price: number;
    description?: string;
}

export type ConvertProductDtoToInner = (product: DomainProduct | undefined) => ProductCardData | undefined;

export const convertProductDtoToInner: ConvertProductDtoToInner = (product: DomainProduct | undefined) => {
    if (product === undefined) return undefined;

    return {
        productId: product.id,
        price: product.cost,
        volume: "",
        description: product.description,
        imageSrc: product.image,
        name: product.name
    } as ProductCardData
}

export const convertProductsDtoToInner = (products: Array<DomainProduct> | undefined): ProductCardData[] | undefined => {
    if (products === undefined) return undefined;

    return products.map(convertProductDtoToInner).filter((productData): productData is ProductCardData => productData !== undefined);
}