import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

interface IconCatalogProps extends SvgIconProps {

}

const IconCatalog: React.FC<IconCatalogProps> = ({ color = '#1D2F49', ...props }) => {
    return (
        <SvgIcon {...props} viewBox="0 0 26 24" sx={{ width: '70%', height: '70%' }}>
            <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="4.2594" y="3.20776" width="7.64322" height="7.64322" rx="2" stroke={color} />
            <rect x="4.2594" y="13.0061" width="7.64322" height="7.64322" rx="2" stroke={color} />
            <rect x="14.1558" y="3.20776" width="7.64322" height="7.64322" rx="2" stroke={color} />
            <rect x="14.1559" y="13.0061" width="7.64322" height="7.64322" rx="2" stroke={color} />
            </svg>
        </SvgIcon>
);
}

export default IconCatalog;