import React from 'react';
import {ProductsByDate} from "@domains/Product/ProductsByDate";
import {Box, Grid, Title, Typography} from "@general-components/common-components";
import ProductsHorizontalScroll from "@general-components/horizontal-scroll/ProductsHorizontalScroll";
import CardProduct from "@general-components/card/card-product/CardProduct";

interface ProductsWithDateBoxProps {
    title: string;
    productsData: Array<ProductsByDate>;
}

const ProductsWithDateBox: React.FC<ProductsWithDateBoxProps> = ({ title, productsData }) => {

    const productsDiv: JSX.Element[] | JSX.Element = productsData.length > 0 ? (productsData.map((productData) => (
        <Box
            fullwidth
            style={{WebkitAlignItems: 'flex-start'}}
        >
            <Typography
                fontWeight={"bolder"}
                variant={"body2"}
                color={"secondary"}
                margin={"10px 0px 5px 0px"}
                align={"left"}
                fullwidth={true}
            >
                {productData.date.toLocaleDateString()}
            </Typography>
            <Grid container spacing={10} horizontal={true}>
                {
                    productData.products.map((product) => (
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <CardProduct product={product} />
                        </Grid>
                    ))
                }
            </Grid>
        </Box>
    ))) : (
        <Box
            fullwidth
            style={{WebkitAlignItems: 'flex-start'}}
        >
            <Typography
                fontWeight={"bolder"}
                variant={"body2"}
                color={"secondary"}
                align={"left"}
                fullwidth={true}
            >
                Пока нет купленных товаров
            </Typography>
        </Box>

    )



    return (
        <Box justifyContent={"flex-start"} style={{WebkitAlignItems: 'flex-start'}} fullwidth>
            <Title title={title} />
            {productsDiv}
        </Box>
    )

}

export default ProductsWithDateBox;