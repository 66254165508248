import React, {useCallback, useEffect, useState} from 'react';
import {Box, Button, NumberField, Typography} from "@general-components/common-components";
import {ProductCardData} from "@domains/Product/ProductCardData";
import {useDispatch, useSelector} from "react-redux";
import {addProductToBasket, getProductBasket, removeProductFromBasket} from "../../../../store/basket/actions";
import {RootState} from "../../../../store/rootReducer";
import useRouter from "@hooks/useRouter";
import {routes} from "../../../../pages/PageContent/settings";

interface BasketButtonProps {
    initialCount?: number;
    productId: number;
}

const BasketButton: React.FC<BasketButtonProps> = ({initialCount = 1, productId}) => {
    const _delay: number = 500;
    const [isVisibleNumberField, setIsVisibleNumberField] = useState<boolean>(false);
    const dispatch = useDispatch();
    const productDataCount = useSelector((state: RootState) =>
        state.basket.basketItems.find(item => item.product.productId === productId)
    )?.count;
    const [currentCount, setCurrentCount] = useState<number>(productDataCount ?? 0);
    const isAuth = useSelector((state: RootState) => state.auth.isAuth);
    const { navigate } = useRouter();

    const setCurrentCountMemo = useCallback((newValue: number) => {
        setCurrentCount(newValue);
    }, []);


    useEffect(() => {
        if (productDataCount) {
            setIsVisibleNumberField(true);
            setCurrentCount(productDataCount);
        }
    }, [productDataCount]);

    useEffect(() => {
        if (isVisibleNumberField && currentCount !== 0) {
            if (!isAuth) {
                navigate(routes.login.path)
            } else {
                const timer = setTimeout(() => {
                    dispatch(addProductToBasket(productId, currentCount));
                }, _delay);

                return () => {
                    clearTimeout(timer);
                };
            }
        }
    }, [currentCount, dispatch, productId, isAuth, navigate]);

    useEffect(() => {
        if (currentCount === 0) {
            dispatch(removeProductFromBasket(productId));
            setIsVisibleNumberField(false);
        }
    }, [currentCount]);

    return (

        <Box>
            {
                (!isVisibleNumberField ? (
                    <Button
                        variant={"outlined"}
                        size={'basket'}
                        onClick={() => {
                            setIsVisibleNumberField(true);
                            setCurrentCount(1)
                        }}
                        fullwidth={true}
                    >
                        <Typography variant="body1" color={"important"} fontWeight={'bolder'}>В корзину</Typography>
                    </Button>
                ) : (
                    <NumberField value={currentCount} onChange={(newValue) => {setCurrentCountMemo(newValue)}} />
                ))
            }
        </Box>

    )
}

export default BasketButton;
