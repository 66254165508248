import React from 'react';
import {Box, Typography} from "@general-components/common-components";
import {Footer, Header} from "../index";
import Image from "@general-components/images/Image";
import Logo from "@images/logo/img.png";
import styled from './DesktopMain.module.sass';
import {IconGeolocation, IconPhone} from "@components/icons";
import useRouter from "@hooks/useRouter";

interface DesktopMainProps {
    children: any;
}

const DesktopMain: React.FC<DesktopMainProps> = ({children}) => {

    const { navigate } = useRouter();

    return (
        <Box fullwidth style={{height: window.innerHeight}} display={"flex"}>
            <Box fullwidth className={styled['header-container']} flexShrink={0}>
                <Header>
                    <Box className={styled['header-desktop']}>
                        <Box className={styled['header-logo']} onClick={() => {navigate('/desktop-main')}}>
                            <Image src={Logo} alt={"logo"} objectFit={'fill'} maxHeight={'50px'} defaultImage={Logo} />
                        </Box>
                        <Box>
                            <Typography variant={"h3"} color={"important"}>
                                its@automacon.ru
                            </Typography>
                        </Box>
                    </Box>
                </Header>
            </Box>
            <Box fullwidth flexGrow={1}>
                {children}
            </Box>
            <Box fullwidth flexShrink={0}>
                <Footer>
                    <Box>
                        <Box display={'flex'} flexDirection={'row'}>
                            <IconGeolocation />
                            <Typography variant={"body1"} color={"textPrimary"} margin={'0 30px 0 0'}>
                                142784, Москва, д. Румянцево, бизнес-парк Румянцево строение 2,
                                подъезд 17, офис 350Г
                            </Typography>
                            <IconPhone />
                            <Typography variant={"body1"} color={"textPrimary"} fontWeight={"bold"}>
                                +7 (455) 664-23-93
                            </Typography>

                        </Box>

                    </Box>
                </Footer>
            </Box>
        </Box>
    )

}

export default DesktopMain;