import React from 'react';
import {Typography} from "@general-components/common-components";

interface TitleProps {
    title: string;
}

const Title: React.FC<TitleProps> = ({ title }) => {
    return <Typography
        variant={'h2'}
        color={'primary'}
        align={'left'}
        fullwidth={true}

    >{title}</Typography>
}

export default Title;