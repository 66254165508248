import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

interface IconUserCircleProps extends SvgIconProps {

}

const IconUserCircle: React.FC<IconUserCircleProps> = ({ color = '#1D2F49', ...props }) => {
    return (
        <SvgIcon {...props} viewBox="0 0 26 24" sx={{ width: '70%', height: '70%' }}>
            <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.2141 19.5C6.2141 19.5 9.8891 18 10.9391 17C11.9891 16 8.8391 16 8.8391 11C8.8391 6 13.0391 6 13.0391 6C13.0391 6 17.2391 6 17.2391 11C17.2391 16 14.0891 16 15.1391 17C16.1891 18 19.8641 19.5 19.8641 19.5" stroke={color} stroke-linecap="round" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0391 22C18.8381 22 23.5391 17.5228 23.5391 12C23.5391 6.47715 18.8381 2 13.0391 2C7.24012 2 2.53911 6.47715 2.53911 12C2.53911 17.5228 7.24012 22 13.0391 22Z" stroke={color} />
            </svg>
        </SvgIcon>
    );
}

export default IconUserCircle;