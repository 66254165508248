import React from 'react';
import styles from './Divider.module.sass';

interface DividerProps {
    orientation?: 'horizontal' | 'vertical';
    style?: React.CSSProperties;
    className?: string;
}

const Divider: React.FC<DividerProps> = ({ orientation = 'horizontal', style = {}, className = '' }) => {
    return (
        <div
            className={`${styles['divider']} ${styles[orientation]}`}
            style={style}
        ></div>
    );
};

export default Divider;
