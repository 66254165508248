import React, {useCallback, useEffect} from 'react';
import BasketPage from "./BasketPage";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/rootReducer";
import {getProductBasket} from "../../store/basket/actions";
import {getRecommendedProductBasket} from "../../store/recommended/actions";
import {createSelector} from "@reduxjs/toolkit";

const selectBasketItems = (state: RootState) => state.basket.basketItems;
const selectRecommended = createSelector(
    (state: RootState) => state.recommended,
    (recommended) => ({
        isLoadedRecProduct: recommended.isLoadedRecProduct,
        isLoadingRecBasket: recommended.isLoadingRecBasket,
        recProductsBasket: recommended.recProductsBasket,
    })
);

interface BasketPageContainerProps {

}

const BasketPageContainer: React.FC<BasketPageContainerProps> = ({}) => {
    const dispatch = useDispatch();

    const basketItems = useSelector(selectBasketItems);
    const { isLoadedRecProduct, isLoadingRecBasket, recProductsBasket } = useSelector(selectRecommended);

    const fetchProductBasket = useCallback(() => {
        dispatch(getProductBasket());
    }, [dispatch]);

    const fetchRecommendedProductBasket = useCallback(() => {
        dispatch(getRecommendedProductBasket(10));
    }, [dispatch]);

    useEffect(() => {
        fetchProductBasket();
        fetchRecommendedProductBasket();
    }, [fetchProductBasket, fetchRecommendedProductBasket]);

    return (
        <BasketPage recommended={recProductsBasket} productsBasket={basketItems} isLoadingRecommended={isLoadingRecBasket}/>
    )

}

export default BasketPageContainer;