import {Notify} from "../../infrastructure/notifications/notification";
import {call, put, takeEvery} from "redux-saga/effects";
import {
    SEARCH_PRODUCTS, setQuerySearchProducts,
    setSearchProducts,
    setStatusLoadedSearchProducts,
    setStatusLoadingSearchProducts
} from "./actions";
import {AxiosResponse} from "axios";
import {productsApi} from "../api/recsysApi";
import {SearchRequestDto} from "@domains/Search/SearchRequestDto";
import {V1SearchProductsResp} from "@domains/ServiceApi/GeneratedData";
import HttpStatus from "@domains/Enums/HttpStatus";
import {convertProductsDtoToInner} from "@domains/Product/ProductCardData";

function* workerSearch(action: any) {
    try {
        const { searchQuery, offset, limit } = action.payload as SearchRequestDto;
        yield put(setQuerySearchProducts(searchQuery));
        const response: AxiosResponse<V1SearchProductsResp> = yield call(productsApi.productsSearchGet.bind(productsApi), searchQuery, offset, limit)
        if (response.status === HttpStatus.OK) {
            const products = convertProductsDtoToInner(response.data.data);
            yield put(setSearchProducts(products ?? []));
        } else {
            Notify.error("Ошибка при поиске товаров");
        }
    } catch (e) {
        yield put(setStatusLoadingSearchProducts(false));
        Notify.error("Ошибка " + JSON.stringify(e));
    }
}

export function* watchSearch() {
    yield takeEvery(SEARCH_PRODUCTS, workerSearch);
}