import React, { FC } from 'react';

interface TypographyProps {
    variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2' | 'caption' | 'button' | 'overline';
    color?: 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'important' | 'error' | string;
    align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
    gutterBottom?: boolean;
    margin?: string | number;
    fontWeight?: 'normal' | 'bold' | 'bolder' | 'lighter' | number;
    fontSize?: string | number;
    children?: React.ReactNode;
    display?: 'block' | 'flex';
    fullwidth?: boolean;
}

const Typography: FC<TypographyProps> = ({
                                             variant = 'body1',
                                             color = 'textPrimary',
                                             align = 'inherit',
                                             gutterBottom = false,
                                             margin,
                                             fontWeight,
                                             fontSize,
                                             children,
                                             display = "block",
                                             fullwidth = false,
                                         }) => {
    const Tag = variant as keyof JSX.IntrinsicElements;

    let colorResult = 'black';

    switch (color) {
        case 'primary':
            colorResult = '#1D2F49';
            break;
        case 'secondary':
            colorResult = '#C1C2CF';
            break;
        case 'textPrimary':
            colorResult = '#1D2F49';
            break;
        case 'textSecondary':
            colorResult = '#C1C2CF';
            break;
        case 'important':
            colorResult = '#3367B4';
            break;
        case 'error':
            colorResult = '#000000';
            break;
        default:
            colorResult = color;
            break;
    }

    const style: React.CSSProperties = {
        color: colorResult,
        textAlign: align,
        margin: margin,
        marginBottom: margin === undefined && gutterBottom ? '0.5rem' : margin,
        fontFamily: 'Roboto',
        display
    };

    if (fontSize !== undefined) {
        style.fontSize = fontSize;
    }

    if (fontWeight !== undefined) {
        style.fontWeight = fontWeight;
    } else {
        if (variant === 'body1' || variant === 'body2') {
            style.fontWeight = '300';
            style.fontSize = '12px';
        } else {
            style.fontWeight = '700';
        }
    }

    if (fullwidth) {
        style.width = "100%";
    }

    return (
        <div>
            <Tag style={style}>
                {children}
            </Tag>
        </div>
    );
};

export default Typography;
