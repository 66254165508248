import React from 'react';
import {Box, Button, Card, Grid, Typography} from "@general-components/common-components";
import Image from "@general-components/images/Image";
import BackgroundRights from "@images/desktop/background.png";
import Robot from "@images/desktop/robot.png";
import styled from './DesktopWithDescription.module.sass';
import useRouter from "@hooks/useRouter";
const DesktopWithDescription: React.FC = ({}) => {

    const router = useRouter();

    return (
        <Box
            style={{backgroundColor: '#EBF0F7'}}
            fullwidth
            height={"100%"}
        >
            <Grid container>
                <Grid item md={6}>
                    <Box className={styled['left-body']} display={"flex"} alignContent={'center'}>
                        <Box maxWidth={'600px'}>
                             <Typography variant={"h1"} color={"primary"} fontWeight={700}>
                                Заголовок
                            </Typography>

                            <Typography
                                variant={"body1"}
                                color={"primary"}
                                fontWeight={300}
                                margin={"20px 0px"}
                                align={"justify"}
                            >
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </Typography>
                            <Typography
                                variant={"body1"}
                                color={"primary"}
                                fontWeight={300}
                                align={"justify"}
                            >
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </Typography>

                            <Box fullwidth>
                                <Button
                                    variant={"primary"}
                                    style={{borderRadius: "0px"}}
                                    onClick={() => {router.navigate('/')}}
                                >Попробовать</Button>
                            </Box>
                        </Box>
                    </Box>

                </Grid>
                <Grid item md={6}>
                    <Box className={styled['right-body']}>
                        <Card>
                            <Image src={BackgroundRights} alt={"robot"} height={"100%"} objectFit={"fill"} className={styled['image-right']}/>
                        </Card>
                    </Box>
                </Grid>
            </Grid>
            <Image src={Robot} alt={"robot"}  className={styled['image-robot']}/>

        </Box>
    )

}

export default DesktopWithDescription;